import { Divider, Select, SelectProps } from 'antd';
import React, { useCallback } from 'react';

export type SelectFieldProps = SelectProps;

export function SelectField(props: SelectFieldProps) {
  const { allowClear = props.mode ? false : true, showArrow = props.mode ? false : true, ...restProps } = props;

  return <Select allowClear={allowClear} showArrow={showArrow} {...restProps} />;
}

export function useRenderDropdownPart(shown: number, total: number) {
  return useCallback(
    (menu: React.ReactElement) => {
      return (
        <>
          {menu}
          {shown < total ? (
            <>
              <Divider style={{ margin: '8px 0' }} />
              <div className="ant-select-item ant-select-item-option-disabled">{`${shown} of ${total} items visible`}</div>
            </>
          ) : null}
        </>
      );
    },
    [shown, total],
  );
}
