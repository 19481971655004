const TOKEN = 'simbaze_token';
const USERID = 'simbaze_user_id';

export class StoregeController {
  static persistToken(tkn?: string) {
    try {
      if (tkn) {
        localStorage.setItem(TOKEN, tkn);
      } else {
        localStorage.removeItem(TOKEN);
      }
    } catch (err) {
      console.error(err);
    }
  }

  static getToken() {
    return localStorage.getItem(TOKEN) ?? undefined;
  }

  static persistUserId(userId?: string) {
    try {
      if (userId) {
        localStorage.setItem(USERID, userId);
      }else {
        localStorage.removeItem(USERID);
      } 
    } catch (err) {
      console.error(err);
    }
  }

  static getUserId() {
    return localStorage.getItem(USERID) ?? undefined;
  }
}






