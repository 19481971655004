import {
  LogoutOutlined,
  UserOutlined,
  CloseCircleOutlined,
  NotificationOutlined,
  MessageOutlined,
} from '@ant-design/icons';
import { Avatar, Badge, Dropdown, DropDownProps, Space, Typography } from 'antd';
import { UserShortDto } from 'js/api/.dist';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AppRoutes } from '../common/AppRoutes';
import { authController } from '../common/AuthController';
import { UserSelect } from './UserSelect';
import { Logo } from './Logo';
import { store } from '../pages/NotificationsPage/store';
import { store as ticketsStore } from '../pages/TicketsPage/store';

const Wrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-left: auto;
`;
const LogoWrapper = styled.div`
  display: flex;
  margin-right: auto;
`;

const UserName = styled(Typography.Title)`
  margin: 0 0 0 auto !important;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

// eslint-disable-next-line complexity
export const HeaderMenu = observer(function AppMenu() {
  const navigate = useNavigate();
  const { user } = authController;
  const canImpersonate = !!user?.roles.some((r) => r === 'IMPERSONATE') || authController.userId;

  useEffect(() => {
    store.fetchCountNotifications();
    ticketsStore.fetchCountTickets();
  }, []);

  const onProfile = useCallback(() => {
    navigate(`/${AppRoutes.MY}`);
  }, [navigate]);

  const onNotifications = useCallback(() => {
    navigate(`/${AppRoutes.NOTIFICATIONS}`);
  }, [navigate]);

  const onTickets = useCallback(() => {
    navigate(`/${AppRoutes.TICKETS}`);
  }, [navigate]);

  const handleLogout = useCallback(() => {
    authController.logout();
    navigate('/login');
  }, [navigate]);

  const handleUserSelect = useCallback((userPayload: UserShortDto | UserShortDto[] | undefined) => {
    if (userPayload) {
      const userId = Array.isArray(userPayload) ? userPayload[0]?.id : userPayload.id;
      authController.userId = userId;
      if (authController.userId !== authController.user?.id) {
        authController.fetchSelectedUser();
        authController.userPromise?.finally(() => {
          window.location.reload();
        });
      }
    }
  }, []);

  const handleClearUser = useCallback(() => {
    authController.userId = undefined;
    authController.fetchUser();
    authController.userPromise?.finally(() => {
      window.location.reload();
    });
  }, []);

  const userMenu: NonNullable<DropDownProps['menu']> = useMemo(
    () => ({
      items: [
        { label: 'Profile', key: 'profile', onClick: onProfile, icon: <UserOutlined /> },
        { label: 'Log out', key: 'logout', danger: true, onClick: handleLogout, icon: <LogoutOutlined /> },
      ],
    }),
    [onProfile, handleLogout],
  );

  return (
    <>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <Wrapper>
        {canImpersonate && (
          <div>
            <UserSelect
              placeholder="Select user"
              allowClear={false}
              onChange={handleUserSelect}
              value={authController.userId ? authController.user : undefined}
              suffixIcon={authController.userId && <CloseCircleOutlined onClick={handleClearUser} />}
            />
          </div>
        )}
        {user && (
          <div>
            <Dropdown menu={userMenu}>
              <Space>
                <Avatar icon={<UserOutlined />} size="small" />
                <UserName level={5}>{`${user.firstName} ${user.lastName}`}</UserName>
              </Space>
            </Dropdown>
            <Badge offset={[8, -4]} size="small" count={store.countNotificationsPromise?.value ?? 0}>
              <NotificationOutlined onClick={onNotifications} style={{ marginLeft: '10px' }} />
            </Badge>
            <Badge offset={[8, -4]} size="small" count={ticketsStore.countTicketsPromise?.value ?? 0}>
              <MessageOutlined onClick={onTickets} style={{ marginLeft: '30px' }} />
            </Badge>
          </div>
        )}
      </Wrapper>
    </>
  );
});
