import { getUsers, getUsersParameters, UserResponse } from '@simbaze/api';
import axios from 'axios';
import debounce from 'lodash/debounce';
import { makeAutoObservable, reaction } from 'mobx';
import { fromPromise, PromiseObserver } from '../../common/FromPromise';

export type PaymentType = 'HOURLY' | 'FIXED' | 'DAILY';
export class Store {
  usersLinkPromise?: PromiseObserver<UserResponse> = undefined;
  userFilterString?: string = undefined;
  paymentType?: PaymentType = undefined;
  accountId?: string = undefined;

  constructor(paymentType?: PaymentType, accountId?: string) {
    this.paymentType = paymentType;
    this.accountId = accountId;

    makeAutoObservable(this);

    reaction(() => this.usersFilter.filter, debounce(this.loadUsers, 300));
  }

  private get usersFilter(): getUsersParameters {
    return {
      filter: this.userFilterString && this.userFilterString.length > 2 ? this.userFilterString : undefined,
      page: 0,
      size: 10,
      order: 'ASC',
      paymentType: this.paymentType,
      accountId: this.accountId,
    };
  }

  setUserFilterString = (userFilterString?: string) => {
    this.userFilterString = userFilterString;
  };

  private loadUsers = () => {
    this.usersLinkPromise?.cancel();
    const cs = axios.CancelToken.source();
    this.usersLinkPromise = fromPromise(getUsers(this.usersFilter, cs.token), {
      cancelSource: cs,
    });
  };

  checkUsers = (check?: boolean) => {
    check ? !this.usersLinkPromise?.fulfilled && this.loadUsers() : this.setUserFilterString();
  };

  clear = () => {
    this.usersLinkPromise?.cancel();
    this.usersLinkPromise = undefined;
    this.userFilterString = undefined;
  };
}
