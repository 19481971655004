import { Typography } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

const { Title } = Typography;

const LogoWrapper = styled.div(
  ({ theme }) => `
  display: flex;
`,
);

const LogoText = styled(Title)`
  margin: 0 !important;
  display: flex;
  align-items: center;
`;

const LogoSide = styled(LogoText)(
  ({ theme }) => `
  color: ${theme.colorPrimary} !important;
`,
);

export const Logo = observer(function Logo() {
  return (
    <LogoWrapper>
      <LogoSide level={3}>{'['}</LogoSide>
      <LogoText level={3}>{'simbaze'}</LogoText>
      <LogoSide level={3}>{']'}</LogoSide>
    </LogoWrapper>
  );
});
