import { TimePicker, TimePickerProps } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React, { useCallback } from 'react';

export type TimeSecondsFieldProps = Omit<TimePickerProps, 'value' | 'onChange'> & {
  value?: number;
  onChange?: (value?: number) => void;
};

export function TimeSecondsField(props: TimeSecondsFieldProps) {
  const { value, onChange, ...restProps } = props;

  const onValueChange = useCallback(
    (val: Dayjs | null) => {
      onChange?.((val?.hour() ?? 0) * 3600 + (val?.minute() ?? 0) * 60 + (val?.second() ?? 0));
    },
    [onChange],
  );

  return (
    <TimePicker
      value={value ? dayjs().startOf('day').set('seconds', value) : undefined}
      onChange={onValueChange}
      showNow={false}
      onSelect={onValueChange}
      {...restProps}
    />
  );
}
