import { TicketResponse, getTickets, getTicketsParameters, TicketDto, markTicketClosed, markTicketClosedParameters, FileDto, downloadAttachment, countTickets  } from '@simbaze/api';
import { TablePaginationConfig } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import axios from 'axios';
import { makeAutoObservable, reaction } from 'mobx';
import { getApiSortOrder } from '../../common/apiHelper';
import { authController } from '../../common/AuthController';
import { fromPromise, PromiseObserver } from '../../common/FromPromise';

const defaultPagination: TablePaginationConfig = {
  current: 1,
  pageSize: 10,
  showSizeChanger: true,
  showTotal(total, [from, to]) {
    return `${from} - ${to} of ${total}`;
  },
};

const defaultSorter: SorterResult<TicketDto> = { field: 'createdTime', order: 'descend' };

let fetched = false;

class TableStore {
  private currentPagination?: TablePaginationConfig = undefined;
  private currentSorter?: SorterResult<TicketDto> = undefined;

  filterString?: string = undefined;
  dataPromise?: PromiseObserver<TicketResponse> = undefined;
  countTicketsPromise?: PromiseObserver<number> = undefined;
  closeTicketsPromise?: PromiseObserver<TicketResponse> = undefined;
  markClosePromise?: PromiseObserver<TicketDto> = undefined;
  imgPreviewPromise?: PromiseObserver<FileDto> = undefined;
  pdfPreviewPromise?: PromiseObserver<FileDto> = undefined;

  constructor() {
    makeAutoObservable(this);

      reaction(
      () => this.sorter,
      () => this.fetch(),
    );

    reaction(
      () => this.pagination.current,
      () => this.fetch(),
    );

    reaction(
      () => this.pagination.pageSize,
      () => this.resetFetch(),
    );

     reaction(
      () => this.markClosePromise?.fulfilled,
        (fulfilled) => fulfilled && (this.fetch(), this.fetchCloseTickets(), this.fetchCountTickets()),
    );

    reaction(
      () => authController.user,
      (user) => !user && this.clear(),
    );
  }

  private get total() {
    return this.dataPromise?.value?.total ?? 0;
  }

  get pagination(): TablePaginationConfig {
    return { ...defaultPagination, ...this.currentPagination, total: this.total };
  }
    
  get sorter(): SorterResult<TicketDto> {
    return { ...defaultSorter, ...this.currentSorter };
  }

  private get requestParams(): getTicketsParameters {
    return {
        page: (this.pagination.current ?? 1) - 1,
        size: this.pagination.pageSize,
        sort: String(this.sorter.field),
        order: getApiSortOrder(this.sorter.order),
    };
  }
  
  public saveRequestParams(): void {
    const params = this.requestParams;
    localStorage.setItem('requestParams', JSON.stringify(params));
  }

  get data(): TicketDto[] {
    if (!fetched) {
      this.fetchLocal();
      fetched = true;
    }

    return this.dataPromise?.value?.items ?? [];
  }

  get dataCloseTickets(): TicketDto[] {
    return this.closeTicketsPromise?.value?.items ?? [];
  }

  get loading() {
    return !!this.dataPromise?.pending;
  }

  private setCurrentPagination = (pagination: TablePaginationConfig) => {
    this.currentPagination = pagination;
  };
    
  private setCurrentSorter = (sorter: SorterResult<TicketDto>) => {
    this.currentSorter = sorter;
  };

  setFilterString = (filterString?: string) => {
    this.filterString = filterString;
  };

  fetchLocal() {
    this.dataPromise?.cancel();
    const cs = axios.CancelToken.source();
    const localParams = localStorage.getItem('requestParams');

    if (localParams) {
      const params = JSON.parse(localParams) as getTicketsParameters;
      this.dataPromise = fromPromise(getTickets(params, cs.token), {
        cancelSource: cs,
        oldData: this.dataPromise?.value,
      });
    } else {
      console.error("No local requestParams found");
    }
  }

  fetch = () => {
    this.dataPromise?.cancel();
    const cs = axios.CancelToken.source();
    this.dataPromise = fromPromise(getTickets(this.requestParams, cs.token), {
      cancelSource: cs,
      oldData: this.dataPromise?.value,
    });
  };

  fetchCountTickets = () => {
    this.countTicketsPromise?.cancel();
    const cs = axios.CancelToken.source();
    this.countTicketsPromise = fromPromise(countTickets({open: true}, cs.token), {
      cancelSource: cs,
      oldData: this.countTicketsPromise?.value,
    });
  };

  fetchCloseTickets = () => {
    this.closeTicketsPromise?.cancel();
    const cs = axios.CancelToken.source();
    const requestParams: getTicketsParameters = {
      ...this.requestParams,
      open: false, 
    };
    this.closeTicketsPromise = fromPromise(getTickets(requestParams, cs.token), {
      cancelSource: cs,
      oldData: this.closeTicketsPromise?.value,
    });
  };

  previewImg = (id?: string) => {
    this.imgPreviewPromise?.cancel();
    const cs = axios.CancelToken.source();
    this.imgPreviewPromise = id
      ? fromPromise(downloadAttachment({ id }, cs.token), {
          cancelSource: cs,
        })
      : undefined;
  };

  previewPdf = (id?: string) => {
    this.pdfPreviewPromise?.cancel();
    const cs = axios.CancelToken.source();
    this.pdfPreviewPromise = id
      ? fromPromise(downloadAttachment({ id }, cs.token), {
          cancelSource: cs,
        })
      : undefined;
  };

  markClosed = (params: markTicketClosedParameters) => {
    this.markClosePromise?.cancel();
    const cs = axios.CancelToken.source();
    this.markClosePromise = fromPromise(markTicketClosed(params, cs.token), { cancelSource: cs });
    return this.markClosePromise;
  };

  private resetFetch = () => {
    this.pagination.current === 1 ? this.fetch() : this.setCurrentPagination({ ...this.currentPagination, current: 1 });
  };
    
  setViewParams = (
    pagination: TablePaginationConfig,
    _: unknown,
    sorter: SorterResult<TicketDto> | SorterResult<TicketDto>[],
  ) => {
    this.setCurrentPagination(pagination);
    !Array.isArray(sorter) && this.setCurrentSorter(sorter);
  };

  clear = () => {
    this.dataPromise?.cancel();
    this.dataPromise = undefined;
    this.countTicketsPromise?.cancel();
    this.countTicketsPromise = undefined;
    this.closeTicketsPromise?.cancel();
    this.closeTicketsPromise = undefined;
    this.pdfPreviewPromise?.cancel();
    this.pdfPreviewPromise = undefined;
    this.imgPreviewPromise?.cancel();
    this.imgPreviewPromise = undefined;
    this.currentPagination = undefined;
    this.currentSorter = undefined;
    this.filterString = undefined;
  };
}

export const store = new TableStore();
