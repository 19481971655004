import axios, { AxiosResponse, CancelToken, AxiosProgressEvent } from 'axios';
import * as Types from './types';
import {
  // @ts-ignore
  DateTimeType,
  // @ts-ignore
  DateType,
  // @ts-ignore
  DateTimeUnixType,
  encodeBody,
  encodeQueryParams,
  transformResponse,
  fromDateType as fromDate,
  toDateType as toDate,
  fromDateTimeType as fromDateTime,
  toDateTimeUnixType as toDateTime,
} from '@simbaze/api-helpers';

export { DateTimeType, DateTimeUnixType, fromDate, toDate, fromDateTime, toDateTime };

export type generateTokenResponse = Promise<AxiosResponse<string>>;

export type generateToken = (form: Types.CredentialForm, cancelToken?: CancelToken) => generateTokenResponse;

export const generateToken: generateToken = async (form, cancelToken) => {
  if (form === undefined) {
    throw new Error('Missing required body: form');
  }

  const path = '/api/token';
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody(form),
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/text',
      'Content-Type': 'application/json',
    },
  });
};

export type getUsersParameters = {
  includeInactive?: boolean;
  invitationPending?: boolean;
  paymentType?: string;
  accountId?: string;
  page?: number;
  size?: number;
  order?: string;
  sort?: string;
  filter?: string;
};
export type getUsersResponse = Promise<AxiosResponse<Types.UserResponse>>;

export type getUsers = (parameters: getUsersParameters, cancelToken?: CancelToken) => getUsersResponse;

export const getUsers: getUsers = async (parameters, cancelToken) => {
  const path = '/api/users';
  const query = {
    includeInactive: parameters.includeInactive,
    invitationPending: parameters.invitationPending,
    paymentType: parameters.paymentType,
    accountId: parameters.accountId,
    page: parameters.page,
    size: parameters.size,
    order: parameters.order,
    sort: parameters.sort,
    filter: parameters.filter,
  };

  return axios({
    method: 'GET',
    url: `${path}${encodeQueryParams(query, { arrayFormat: 'comma' })}`,
    data: {},
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
    },
  });
};

export type createUserResponse = Promise<AxiosResponse<Types.UserDto>>;

export type createUser = (form: Types.UserForm, cancelToken?: CancelToken) => createUserResponse;

export const createUser: createUser = async (form, cancelToken) => {
  if (form === undefined) {
    throw new Error('Missing required body: form');
  }

  const path = '/api/users';
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody(form),
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

export type getUserByIdParameters = { id: string };
export type getUserByIdResponse = Promise<AxiosResponse<Types.UserDto>>;

export type getUserById = (parameters: getUserByIdParameters, cancelToken?: CancelToken) => getUserByIdResponse;

export const getUserById: getUserById = async (parameters, cancelToken) => {
  if (parameters.id === undefined) {
    throw new Error('Missing required parameter: id');
  }

  const path = '/api/users/{id}'.replace('{id}', parameters.id?.toString());
  return axios({
    method: 'GET',
    url: path,
    data: {},
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
    },
  });
};

export type updateUserParameters = { id: string };
export type updateUserResponse = Promise<AxiosResponse<Types.UserDto>>;

export type updateUser = (
  form: Types.UserForm,
  parameters: updateUserParameters,
  cancelToken?: CancelToken,
) => updateUserResponse;

export const updateUser: updateUser = async (form, parameters, cancelToken) => {
  if (form === undefined) {
    throw new Error('Missing required body: form');
  }
  if (parameters.id === undefined) {
    throw new Error('Missing required parameter: id');
  }

  const path = '/api/users/{id}'.replace('{id}', parameters.id?.toString());
  return axios({
    method: 'PUT',
    url: path,
    data: encodeBody(form),
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

export type updateUserProfileParameters = { id: string };
export type updateUserProfileResponse = Promise<AxiosResponse<Types.UserDto>>;

export type updateUserProfile = (
  form: Types.UserProfileForm,
  parameters: updateUserProfileParameters,
  cancelToken?: CancelToken,
) => updateUserProfileResponse;

export const updateUserProfile: updateUserProfile = async (form, parameters, cancelToken) => {
  if (form === undefined) {
    throw new Error('Missing required body: form');
  }
  if (parameters.id === undefined) {
    throw new Error('Missing required parameter: id');
  }

  const path = '/api/users/{id}/profile'.replace('{id}', parameters.id?.toString());
  return axios({
    method: 'PUT',
    url: path,
    data: encodeBody(form),
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

export type makeUserActiveParameters = { id: string; active: boolean };
export type makeUserActiveResponse = Promise<AxiosResponse<Types.UserDto>>;

export type makeUserActive = (
  parameters: makeUserActiveParameters,
  cancelToken?: CancelToken,
) => makeUserActiveResponse;

export const makeUserActive: makeUserActive = async (parameters, cancelToken) => {
  if (parameters.id === undefined) {
    throw new Error('Missing required parameter: id');
  }
  if (parameters.active === undefined) {
    throw new Error('Missing required parameter: active');
  }

  const path = '/api/users/{id}/make-active'.replace('{id}', parameters.id?.toString());
  const query = {
    active: parameters.active,
  };

  return axios({
    method: 'PUT',
    url: `${path}${encodeQueryParams(query, { arrayFormat: 'comma' })}`,
    data: {},
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
    },
  });
};

export type getCurrentUserParameters = { selectedUserId?: string };
export type getCurrentUserResponse = Promise<AxiosResponse<Types.UserDto>>;

export type getCurrentUser = (
  parameters: getCurrentUserParameters,
  cancelToken?: CancelToken,
) => getCurrentUserResponse;

export const getCurrentUser: getCurrentUser = async (parameters, cancelToken) => {
  const path = '/api/users/current_user';
  const query = {
    selectedUserId: parameters.selectedUserId,
  };

  return axios({
    method: 'GET',
    url: `${path}${encodeQueryParams(query, { arrayFormat: 'comma' })}`,
    data: {},
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
    },
  });
};

export type inviteUserParameters = { email: string };
export type inviteUserResponse = Promise<AxiosResponse<any>>;

export type inviteUser = (parameters: inviteUserParameters, cancelToken?: CancelToken) => inviteUserResponse;

export const inviteUser: inviteUser = async (parameters, cancelToken) => {
  if (parameters.email === undefined) {
    throw new Error('Missing required parameter: email');
  }

  const path = '/api/users/invite';
  const query = {
    email: parameters.email,
  };

  return axios({
    method: 'POST',
    url: `${path}${encodeQueryParams(query, { arrayFormat: 'comma' })}`,
    data: {},
    cancelToken,
    transformResponse,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export type activateUserParameters = { invitationCode: string };
export type activateUserResponse = Promise<AxiosResponse<Types.UserShortDto>>;

export type activateUser = (
  form: Types.UserActivationForm,
  parameters: activateUserParameters,
  cancelToken?: CancelToken,
) => activateUserResponse;

export const activateUser: activateUser = async (form, parameters, cancelToken) => {
  if (form === undefined) {
    throw new Error('Missing required body: form');
  }
  if (parameters.invitationCode === undefined) {
    throw new Error('Missing required parameter: invitationCode');
  }

  const path = '/api/users/activate';
  const query = {
    invitationCode: parameters.invitationCode,
  };

  return axios({
    method: 'PUT',
    url: `${path}${encodeQueryParams(query, { arrayFormat: 'comma' })}`,
    data: encodeBody(form),
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

export type getUserByInvitationCodeParameters = { invitationCode: string };
export type getUserByInvitationCodeResponse = Promise<AxiosResponse<Types.UserShortDto>>;

export type getUserByInvitationCode = (
  parameters: getUserByInvitationCodeParameters,
  cancelToken?: CancelToken,
) => getUserByInvitationCodeResponse;

export const getUserByInvitationCode: getUserByInvitationCode = async (parameters, cancelToken) => {
  if (parameters.invitationCode === undefined) {
    throw new Error('Missing required parameter: invitationCode');
  }

  const path = '/api/users/by-invitation-code';
  const query = {
    invitationCode: parameters.invitationCode,
  };

  return axios({
    method: 'GET',
    url: `${path}${encodeQueryParams(query, { arrayFormat: 'comma' })}`,
    data: {},
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
    },
  });
};

export type addUserSignatureParameters = { id: string };
export type addUserSignatureResponse = Promise<AxiosResponse<any>>;

export type addUserSignature = (
  formData: any,
  parameters: addUserSignatureParameters,
  onUploadProgress?: (event: AxiosProgressEvent) => void,
  cancelToken?: CancelToken,
) => addUserSignatureResponse;

export const addUserSignature: addUserSignature = async (formData, parameters, onUploadProgress, cancelToken) => {
  if (parameters.id === undefined) {
    throw new Error('Missing required parameter: id');
  }

  const path = '/api/users/{id}/add-signature'.replace('{id}', parameters.id?.toString());
  return axios({
    method: 'PUT',
    url: path,
    data: formData,
    onUploadProgress,
    cancelToken,
    transformResponse,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export type getUserSignatureParameters = { id: string };
export type getUserSignatureResponse = Promise<AxiosResponse<Types.FileDto>>;

export type getUserSignature = (
  parameters: getUserSignatureParameters,
  cancelToken?: CancelToken,
) => getUserSignatureResponse;

export const getUserSignature: getUserSignature = async (parameters, cancelToken) => {
  if (parameters.id === undefined) {
    throw new Error('Missing required parameter: id');
  }

  const path = '/api/users/{id}/get-signature'.replace('{id}', parameters.id?.toString());
  return axios({
    method: 'GET',
    url: path,
    data: {},
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
    },
  });
};

export type getUserRatesByAccountIdParameters = { accountId: string };
export type getUserRatesByAccountIdResponse = Promise<AxiosResponse<Types.UserRateDto[]>>;

export type getUserRatesByAccountId = (
  parameters: getUserRatesByAccountIdParameters,
  cancelToken?: CancelToken,
) => getUserRatesByAccountIdResponse;

export const getUserRatesByAccountId: getUserRatesByAccountId = async (parameters, cancelToken) => {
  if (parameters.accountId === undefined) {
    throw new Error('Missing required parameter: accountId');
  }

  const path = '/api/users/rates-by-account';
  const query = {
    accountId: parameters.accountId,
  };

  return axios({
    method: 'GET',
    url: `${path}${encodeQueryParams(query, { arrayFormat: 'comma' })}`,
    data: {},
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
    },
  });
};

export type getAccountsParameters = { page?: number; size?: number; order?: string; sort?: string; filter?: string };
export type getAccountsResponse = Promise<AxiosResponse<Types.AccountResponse>>;

export type getAccounts = (parameters: getAccountsParameters, cancelToken?: CancelToken) => getAccountsResponse;

export const getAccounts: getAccounts = async (parameters, cancelToken) => {
  const path = '/api/accounts';
  const query = {
    page: parameters.page,
    size: parameters.size,
    order: parameters.order,
    sort: parameters.sort,
    filter: parameters.filter,
  };

  return axios({
    method: 'GET',
    url: `${path}${encodeQueryParams(query, { arrayFormat: 'comma' })}`,
    data: {},
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
    },
  });
};

export type createAccountResponse = Promise<AxiosResponse<Types.AccountDto>>;

export type createAccount = (form: Types.AccountForm, cancelToken?: CancelToken) => createAccountResponse;

export const createAccount: createAccount = async (form, cancelToken) => {
  if (form === undefined) {
    throw new Error('Missing required body: form');
  }

  const path = '/api/accounts';
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody(form),
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

export type getAccountByIdParameters = { id: string };
export type getAccountByIdResponse = Promise<AxiosResponse<Types.AccountDto>>;

export type getAccountById = (
  parameters: getAccountByIdParameters,
  cancelToken?: CancelToken,
) => getAccountByIdResponse;

export const getAccountById: getAccountById = async (parameters, cancelToken) => {
  if (parameters.id === undefined) {
    throw new Error('Missing required parameter: id');
  }

  const path = '/api/accounts/{id}'.replace('{id}', parameters.id?.toString());
  return axios({
    method: 'GET',
    url: path,
    data: {},
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
    },
  });
};

export type updateAccountParameters = { id: string };
export type updateAccountResponse = Promise<AxiosResponse<Types.AccountDto>>;

export type updateAccount = (
  form: Types.AccountForm,
  parameters: updateAccountParameters,
  cancelToken?: CancelToken,
) => updateAccountResponse;

export const updateAccount: updateAccount = async (form, parameters, cancelToken) => {
  if (form === undefined) {
    throw new Error('Missing required body: form');
  }
  if (parameters.id === undefined) {
    throw new Error('Missing required parameter: id');
  }

  const path = '/api/accounts/{id}'.replace('{id}', parameters.id?.toString());
  return axios({
    method: 'PUT',
    url: path,
    data: encodeBody(form),
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

export type getPaymentPeriodsParameters = {
  paymentPeriodType: string;
  page?: number;
  size?: number;
  order?: string;
  sort?: string;
};
export type getPaymentPeriodsResponse = Promise<AxiosResponse<Types.PaymentPeriodResponse>>;

export type getPaymentPeriods = (
  parameters: getPaymentPeriodsParameters,
  cancelToken?: CancelToken,
) => getPaymentPeriodsResponse;

export const getPaymentPeriods: getPaymentPeriods = async (parameters, cancelToken) => {
  if (parameters.paymentPeriodType === undefined) {
    throw new Error('Missing required parameter: paymentPeriodType');
  }

  const path = '/api/payment-periods';
  const query = {
    paymentPeriodType: parameters.paymentPeriodType,
    page: parameters.page,
    size: parameters.size,
    order: parameters.order,
    sort: parameters.sort,
  };

  return axios({
    method: 'GET',
    url: `${path}${encodeQueryParams(query, { arrayFormat: 'comma' })}`,
    data: {},
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
    },
  });
};

export type createUserVacationResponse = Promise<AxiosResponse<Types.UserVacationDto>>;

export type createUserVacation = (
  form: Types.UserVacationForm,
  cancelToken?: CancelToken,
) => createUserVacationResponse;

export const createUserVacation: createUserVacation = async (form, cancelToken) => {
  if (form === undefined) {
    throw new Error('Missing required body: form');
  }

  const path = '/api/user-vacations';
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody(form),
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

export type getUserVacationsParameters = {
  page?: number;
  size?: number;
  order?: string;
  sort?: string;
  startDate: string;
  endDate: string;
};
export type getUserVacationsResponse = Promise<AxiosResponse<Types.UserVacationResponse>>;

export type getUserVacations = (
  parameters: getUserVacationsParameters,
  cancelToken?: CancelToken,
) => getUserVacationsResponse;

export const getUserVacations: getUserVacations = async (parameters, cancelToken) => {
  if (parameters.startDate === undefined) {
    throw new Error('Missing required parameter: startDate');
  }
  if (parameters.endDate === undefined) {
    throw new Error('Missing required parameter: endDate');
  }

  const path = '/api/user-vacations';
  const query = {
    page: parameters.page,
    size: parameters.size,
    order: parameters.order,
    sort: parameters.sort,
    startDate: parameters.startDate,
    endDate: parameters.endDate,
  };

  return axios({
    method: 'GET',
    url: `${path}${encodeQueryParams(query, { arrayFormat: 'comma' })}`,
    data: {},
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
    },
  });
};

export type updateUserVacationParameters = { id: string };
export type updateUserVacationResponse = Promise<AxiosResponse<Types.UserVacationDto>>;

export type updateUserVacation = (
  form: Types.UserVacationForm,
  parameters: updateUserVacationParameters,
  cancelToken?: CancelToken,
) => updateUserVacationResponse;

export const updateUserVacation: updateUserVacation = async (form, parameters, cancelToken) => {
  if (parameters.id === undefined) {
    throw new Error('Missing required parameter: id');
  }
  if (form === undefined) {
    throw new Error('Missing required body: form');
  }

  const path = '/api/user-vacations/{id}'.replace('{id}', parameters.id?.toString());
  return axios({
    method: 'PUT',
    url: path,
    data: encodeBody(form),
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

export type deleteUserVacationParameters = { id: string };
export type deleteUserVacationResponse = Promise<AxiosResponse<any>>;

export type deleteUserVacation = (
  parameters: deleteUserVacationParameters,
  cancelToken?: CancelToken,
) => deleteUserVacationResponse;

export const deleteUserVacation: deleteUserVacation = async (parameters, cancelToken) => {
  if (parameters.id === undefined) {
    throw new Error('Missing required parameter: id');
  }

  const path = '/api/user-vacations/{id}'.replace('{id}', parameters.id?.toString());
  return axios({
    method: 'DELETE',
    url: path,
    data: {},
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
    },
  });
};

export type getUserVacationUsersResponse = Promise<AxiosResponse<Types.UserVacationUserResponse>>;

export type getUserVacationUsers = (cancelToken?: CancelToken) => getUserVacationUsersResponse;

export const getUserVacationUsers: getUserVacationUsers = async (cancelToken) => {
  const path = '/api/user-vacations/users';
  return axios({
    method: 'GET',
    url: path,
    data: {},
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
    },
  });
};

export type getUserVacationStatisticsParameters = { userId: string };
export type getUserVacationStatisticsResponse = Promise<AxiosResponse<Types.UserVacationStatisticsDto>>;

export type getUserVacationStatistics = (
  parameters: getUserVacationStatisticsParameters,
  cancelToken?: CancelToken,
) => getUserVacationStatisticsResponse;

export const getUserVacationStatistics: getUserVacationStatistics = async (parameters, cancelToken) => {
  if (parameters.userId === undefined) {
    throw new Error('Missing required parameter: userId');
  }

  const path = '/api/user-vacations/statistics';
  const query = {
    userId: parameters.userId,
  };

  return axios({
    method: 'GET',
    url: `${path}${encodeQueryParams(query, { arrayFormat: 'comma' })}`,
    data: {},
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
    },
  });
};

export type subscribeToUserVacationsParameters = { userToSubscribeId: string };
export type subscribeToUserVacationsResponse = Promise<AxiosResponse<any>>;

export type subscribeToUserVacations = (
  parameters: subscribeToUserVacationsParameters,
  cancelToken?: CancelToken,
) => subscribeToUserVacationsResponse;

export const subscribeToUserVacations: subscribeToUserVacations = async (parameters, cancelToken) => {
  if (parameters.userToSubscribeId === undefined) {
    throw new Error('Missing required parameter: userToSubscribeId');
  }

  const path = '/api/user-vacations/subscribe';
  const query = {
    userToSubscribeId: parameters.userToSubscribeId,
  };

  return axios({
    method: 'PUT',
    url: `${path}${encodeQueryParams(query, { arrayFormat: 'comma' })}`,
    data: {},
    cancelToken,
    transformResponse,
  });
};

export type unsubscribeFromUserVacationsParameters = { userToUnsubscribeId: string };
export type unsubscribeFromUserVacationsResponse = Promise<AxiosResponse<any>>;

export type unsubscribeFromUserVacations = (
  parameters: unsubscribeFromUserVacationsParameters,
  cancelToken?: CancelToken,
) => unsubscribeFromUserVacationsResponse;

export const unsubscribeFromUserVacations: unsubscribeFromUserVacations = async (parameters, cancelToken) => {
  if (parameters.userToUnsubscribeId === undefined) {
    throw new Error('Missing required parameter: userToUnsubscribeId');
  }

  const path = '/api/user-vacations/unsubscribe';
  const query = {
    userToUnsubscribeId: parameters.userToUnsubscribeId,
  };

  return axios({
    method: 'DELETE',
    url: `${path}${encodeQueryParams(query, { arrayFormat: 'comma' })}`,
    data: {},
    cancelToken,
    transformResponse,
  });
};

export type getCandidatesResponse = Promise<AxiosResponse<Types.CandidateResponse>>;

export type getCandidates = (form: Types.CandidateFilter, cancelToken?: CancelToken) => getCandidatesResponse;

export const getCandidates: getCandidates = async (form, cancelToken) => {
  if (form === undefined) {
    throw new Error('Missing required body: form');
  }

  const path = '/api/candidates/get';
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody(form),
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

export type createCandidateResponse = Promise<AxiosResponse<Types.CandidateDto>>;

export type createCandidate = (form: Types.CandidateForm, cancelToken?: CancelToken) => createCandidateResponse;

export const createCandidate: createCandidate = async (form, cancelToken) => {
  if (form === undefined) {
    throw new Error('Missing required body: form');
  }

  const path = '/api/candidates';
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody(form),
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

export type getCandidateByIdParameters = { id: string };
export type getCandidateByIdResponse = Promise<AxiosResponse<Types.CandidateDto>>;

export type getCandidateById = (
  parameters: getCandidateByIdParameters,
  cancelToken?: CancelToken,
) => getCandidateByIdResponse;

export const getCandidateById: getCandidateById = async (parameters, cancelToken) => {
  if (parameters.id === undefined) {
    throw new Error('Missing required parameter: id');
  }

  const path = '/api/candidates/{id}'.replace('{id}', parameters.id?.toString());
  return axios({
    method: 'GET',
    url: path,
    data: {},
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
    },
  });
};

export type updateCandidateParameters = { id: string };
export type updateCandidateResponse = Promise<AxiosResponse<Types.CandidateDto>>;

export type updateCandidate = (
  form: Types.CandidateForm,
  parameters: updateCandidateParameters,
  cancelToken?: CancelToken,
) => updateCandidateResponse;

export const updateCandidate: updateCandidate = async (form, parameters, cancelToken) => {
  if (form === undefined) {
    throw new Error('Missing required body: form');
  }
  if (parameters.id === undefined) {
    throw new Error('Missing required parameter: id');
  }

  const path = '/api/candidates/{id}'.replace('{id}', parameters.id?.toString());
  return axios({
    method: 'PUT',
    url: path,
    data: encodeBody(form),
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

export type deleteCandidateParameters = { id: string };
export type deleteCandidateResponse = Promise<AxiosResponse<any>>;

export type deleteCandidate = (
  parameters: deleteCandidateParameters,
  cancelToken?: CancelToken,
) => deleteCandidateResponse;

export const deleteCandidate: deleteCandidate = async (parameters, cancelToken) => {
  if (parameters.id === undefined) {
    throw new Error('Missing required parameter: id');
  }

  const path = '/api/candidates/{id}'.replace('{id}', parameters.id?.toString());
  return axios({
    method: 'DELETE',
    url: path,
    data: {},
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
    },
  });
};

export type getCandidateActivitiesParameters = {
  id: string;
  page?: number;
  size?: number;
  order?: string;
  sort?: string;
};
export type getCandidateActivitiesResponse = Promise<AxiosResponse<Types.CandidateActivityResponse>>;

export type getCandidateActivities = (
  parameters: getCandidateActivitiesParameters,
  cancelToken?: CancelToken,
) => getCandidateActivitiesResponse;

export const getCandidateActivities: getCandidateActivities = async (parameters, cancelToken) => {
  if (parameters.id === undefined) {
    throw new Error('Missing required parameter: id');
  }

  const path = '/api/candidates/{id}/activities'.replace('{id}', parameters.id?.toString());
  const query = {
    page: parameters.page,
    size: parameters.size,
    order: parameters.order,
    sort: parameters.sort,
  };

  return axios({
    method: 'GET',
    url: `${path}${encodeQueryParams(query, { arrayFormat: 'comma' })}`,
    data: {},
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
    },
  });
};

export type createCandidateActivityParameters = { id: string };
export type createCandidateActivityResponse = Promise<AxiosResponse<Types.CandidateActivityDto>>;

export type createCandidateActivity = (
  form: Types.CandidateActivityForm,
  parameters: createCandidateActivityParameters,
  cancelToken?: CancelToken,
) => createCandidateActivityResponse;

export const createCandidateActivity: createCandidateActivity = async (form, parameters, cancelToken) => {
  if (form === undefined) {
    throw new Error('Missing required body: form');
  }
  if (parameters.id === undefined) {
    throw new Error('Missing required parameter: id');
  }

  const path = '/api/candidates/{id}/activities'.replace('{id}', parameters.id?.toString());
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody(form),
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

export type getActualCandidateActivitiesParameters = {
  allAppointed?: boolean;
  page?: number;
  size?: number;
  order?: string;
  sort?: string;
};
export type getActualCandidateActivitiesResponse = Promise<AxiosResponse<Types.CandidateActivityResponse>>;

export type getActualCandidateActivities = (
  parameters: getActualCandidateActivitiesParameters,
  cancelToken?: CancelToken,
) => getActualCandidateActivitiesResponse;

export const getActualCandidateActivities: getActualCandidateActivities = async (parameters, cancelToken) => {
  const path = '/api/candidates/activities/actual';
  const query = {
    allAppointed: parameters.allAppointed,
    page: parameters.page,
    size: parameters.size,
    order: parameters.order,
    sort: parameters.sort,
  };

  return axios({
    method: 'GET',
    url: `${path}${encodeQueryParams(query, { arrayFormat: 'comma' })}`,
    data: {},
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
    },
  });
};

export type updateCandidateActivityParameters = { id: string };
export type updateCandidateActivityResponse = Promise<AxiosResponse<Types.CandidateActivityDto>>;

export type updateCandidateActivity = (
  form: Types.CandidateActivityForm,
  parameters: updateCandidateActivityParameters,
  cancelToken?: CancelToken,
) => updateCandidateActivityResponse;

export const updateCandidateActivity: updateCandidateActivity = async (form, parameters, cancelToken) => {
  if (form === undefined) {
    throw new Error('Missing required body: form');
  }
  if (parameters.id === undefined) {
    throw new Error('Missing required parameter: id');
  }

  const path = '/api/candidates/activities/{id}'.replace('{id}', parameters.id?.toString());
  return axios({
    method: 'PUT',
    url: path,
    data: encodeBody(form),
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

export type deleteCandidateActivityParameters = { id: string };
export type deleteCandidateActivityResponse = Promise<AxiosResponse<any>>;

export type deleteCandidateActivity = (
  parameters: deleteCandidateActivityParameters,
  cancelToken?: CancelToken,
) => deleteCandidateActivityResponse;

export const deleteCandidateActivity: deleteCandidateActivity = async (parameters, cancelToken) => {
  if (parameters.id === undefined) {
    throw new Error('Missing required parameter: id');
  }

  const path = '/api/candidates/activities/{id}'.replace('{id}', parameters.id?.toString());
  return axios({
    method: 'DELETE',
    url: path,
    data: {},
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
    },
  });
};

export type markProcessedParameters = { id: string; processed: boolean };
export type markProcessedResponse = Promise<AxiosResponse<Types.CandidateActivityDto>>;

export type markProcessed = (parameters: markProcessedParameters, cancelToken?: CancelToken) => markProcessedResponse;

export const markProcessed: markProcessed = async (parameters, cancelToken) => {
  if (parameters.id === undefined) {
    throw new Error('Missing required parameter: id');
  }
  if (parameters.processed === undefined) {
    throw new Error('Missing required parameter: processed');
  }

  const path = '/api/candidates/activities/{id}/mark-processed'.replace('{id}', parameters.id?.toString());
  const query = {
    processed: parameters.processed,
  };

  return axios({
    method: 'PUT',
    url: `${path}${encodeQueryParams(query, { arrayFormat: 'comma' })}`,
    data: {},
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
    },
  });
};

export type createUserPaymentResponse = Promise<AxiosResponse<Types.UserPaymentDto>>;

export type createUserPayment = (
  userPaymentForm: Types.UserPaymentForm,
  cancelToken?: CancelToken,
) => createUserPaymentResponse;

export const createUserPayment: createUserPayment = async (userPaymentForm, cancelToken) => {
  if (userPaymentForm === undefined) {
    throw new Error('Missing required body: userPaymentForm');
  }

  const path = '/api/user-payments';
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody(userPaymentForm),
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

export type calculateUserPaymentResponse = Promise<AxiosResponse<Types.UserPaymentDto>>;

export type calculateUserPayment = (
  form: Types.UserPaymentForm,
  cancelToken?: CancelToken,
) => calculateUserPaymentResponse;

export const calculateUserPayment: calculateUserPayment = async (form, cancelToken) => {
  if (form === undefined) {
    throw new Error('Missing required body: form');
  }

  const path = '/api/user-payments/calculate';
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody(form),
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

export type getUserPaymentByIdParameters = { id: string };
export type getUserPaymentByIdResponse = Promise<AxiosResponse<Types.UserPaymentDto>>;

export type getUserPaymentById = (
  parameters: getUserPaymentByIdParameters,
  cancelToken?: CancelToken,
) => getUserPaymentByIdResponse;

export const getUserPaymentById: getUserPaymentById = async (parameters, cancelToken) => {
  if (parameters.id === undefined) {
    throw new Error('Missing required parameter: id');
  }

  const path = '/api/user-payments/{id}'.replace('{id}', parameters.id?.toString());
  return axios({
    method: 'GET',
    url: path,
    data: {},
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
    },
  });
};

export type updateUserPaymentParameters = { id: string };
export type updateUserPaymentResponse = Promise<AxiosResponse<Types.UserPaymentDto>>;

export type updateUserPayment = (
  form: Types.UserPaymentForm,
  parameters: updateUserPaymentParameters,
  cancelToken?: CancelToken,
) => updateUserPaymentResponse;

export const updateUserPayment: updateUserPayment = async (form, parameters, cancelToken) => {
  if (form === undefined) {
    throw new Error('Missing required body: form');
  }
  if (parameters.id === undefined) {
    throw new Error('Missing required parameter: id');
  }

  const path = '/api/user-payments/{id}'.replace('{id}', parameters.id?.toString());
  return axios({
    method: 'PUT',
    url: path,
    data: encodeBody(form),
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

export type deleteUserPaymentParameters = { id: string };
export type deleteUserPaymentResponse = Promise<AxiosResponse<any>>;

export type deleteUserPayment = (
  parameters: deleteUserPaymentParameters,
  cancelToken?: CancelToken,
) => deleteUserPaymentResponse;

export const deleteUserPayment: deleteUserPayment = async (parameters, cancelToken) => {
  if (parameters.id === undefined) {
    throw new Error('Missing required parameter: id');
  }

  const path = '/api/user-payments/{id}'.replace('{id}', parameters.id?.toString());
  return axios({
    method: 'DELETE',
    url: path,
    data: {},
    cancelToken,
    transformResponse,
  });
};

export type deleteUserPaymentsParameters = { ids?: string[] };
export type deleteUserPaymentsResponse = Promise<AxiosResponse<any>>;

export type deleteUserPayments = (
  parameters: deleteUserPaymentsParameters,
  cancelToken?: CancelToken,
) => deleteUserPaymentsResponse;

export const deleteUserPayments: deleteUserPayments = async (parameters, cancelToken) => {
  const path = '/api/user-payments/bulk-delete';
  const query = {
    ids: parameters.ids,
  };

  return axios({
    method: 'DELETE',
    url: `${path}${encodeQueryParams(query, { arrayFormat: 'comma' })}`,
    data: {},
    cancelToken,
    transformResponse,
  });
};

export type markAsPaidUserPaymentParameters = { id: string; paid: boolean };
export type markAsPaidUserPaymentResponse = Promise<AxiosResponse<Types.UserPaymentDto>>;

export type markAsPaidUserPayment = (
  parameters: markAsPaidUserPaymentParameters,
  cancelToken?: CancelToken,
) => markAsPaidUserPaymentResponse;

export const markAsPaidUserPayment: markAsPaidUserPayment = async (parameters, cancelToken) => {
  if (parameters.id === undefined) {
    throw new Error('Missing required parameter: id');
  }
  if (parameters.paid === undefined) {
    throw new Error('Missing required parameter: paid');
  }

  const path = '/api/user-payments/{id}/paid'.replace('{id}', parameters.id?.toString());
  const query = {
    paid: parameters.paid,
  };

  return axios({
    method: 'PUT',
    url: `${path}${encodeQueryParams(query, { arrayFormat: 'comma' })}`,
    data: {},
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
    },
  });
};

export type getUserPaymentHistoryParameters = {
  id: string;
  page?: number;
  size?: number;
  order?: string;
  sort?: string;
};
export type getUserPaymentHistoryResponse = Promise<AxiosResponse<Types.UserPaymentHistoryResponse>>;

export type getUserPaymentHistory = (
  parameters: getUserPaymentHistoryParameters,
  cancelToken?: CancelToken,
) => getUserPaymentHistoryResponse;

export const getUserPaymentHistory: getUserPaymentHistory = async (parameters, cancelToken) => {
  if (parameters.id === undefined) {
    throw new Error('Missing required parameter: id');
  }

  const path = '/api/user-payments/{id}/history'.replace('{id}', parameters.id?.toString());
  const query = {
    page: parameters.page,
    size: parameters.size,
    order: parameters.order,
    sort: parameters.sort,
  };

  return axios({
    method: 'GET',
    url: `${path}${encodeQueryParams(query, { arrayFormat: 'comma' })}`,
    data: {},
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
    },
  });
};

export type markAsPaidUserPaymentsParameters = { ids?: string[]; paid: boolean };
export type markAsPaidUserPaymentsResponse = Promise<AxiosResponse<Types.UserPaymentDto[]>>;

export type markAsPaidUserPayments = (
  parameters: markAsPaidUserPaymentsParameters,
  cancelToken?: CancelToken,
) => markAsPaidUserPaymentsResponse;

export const markAsPaidUserPayments: markAsPaidUserPayments = async (parameters, cancelToken) => {
  if (parameters.paid === undefined) {
    throw new Error('Missing required parameter: paid');
  }

  const path = '/api/user-payments/bulk-paid';
  const query = {
    ids: parameters.ids,
    paid: parameters.paid,
  };

  return axios({
    method: 'PUT',
    url: `${path}${encodeQueryParams(query, { arrayFormat: 'comma' })}`,
    data: {},
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
    },
  });
};

export type getUserPaymentsResponse = Promise<AxiosResponse<Types.UserPaymentResponse>>;

export type getUserPayments = (form: Types.UserPaymentsFilter, cancelToken?: CancelToken) => getUserPaymentsResponse;

export const getUserPayments: getUserPayments = async (form, cancelToken) => {
  if (form === undefined) {
    throw new Error('Missing required body: form');
  }

  const path = '/api/user-payments/get';
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody(form),
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

export type createAccountInvoiceResponse = Promise<AxiosResponse<Types.AccountInvoiceDto>>;

export type createAccountInvoice = (
  form: Types.AccountInvoiceForm,
  cancelToken?: CancelToken,
) => createAccountInvoiceResponse;

export const createAccountInvoice: createAccountInvoice = async (form, cancelToken) => {
  if (form === undefined) {
    throw new Error('Missing required body: form');
  }

  const path = '/api/account-invoices';
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody(form),
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

export type calculateAccountInvoiceResponse = Promise<AxiosResponse<Types.AccountInvoiceDto>>;

export type calculateAccountInvoice = (
  form: Types.AccountInvoiceForm,
  cancelToken?: CancelToken,
) => calculateAccountInvoiceResponse;

export const calculateAccountInvoice: calculateAccountInvoice = async (form, cancelToken) => {
  if (form === undefined) {
    throw new Error('Missing required body: form');
  }

  const path = '/api/account-invoices/calculate';
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody(form),
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

export type getAccountInvoicesResponse = Promise<AxiosResponse<Types.AccountInvoiceResponse>>;

export type getAccountInvoices = (
  form: Types.AccountInvoiceFilter,
  cancelToken?: CancelToken,
) => getAccountInvoicesResponse;

export const getAccountInvoices: getAccountInvoices = async (form, cancelToken) => {
  if (form === undefined) {
    throw new Error('Missing required body: form');
  }

  const path = '/api/account-invoices/get';
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody(form),
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

export type updateAccountInvoiceParameters = { id: string };
export type updateAccountInvoiceResponse = Promise<AxiosResponse<Types.AccountInvoiceDto>>;

export type updateAccountInvoice = (
  form: Types.AccountInvoiceForm,
  parameters: updateAccountInvoiceParameters,
  cancelToken?: CancelToken,
) => updateAccountInvoiceResponse;

export const updateAccountInvoice: updateAccountInvoice = async (form, parameters, cancelToken) => {
  if (form === undefined) {
    throw new Error('Missing required body: form');
  }
  if (parameters.id === undefined) {
    throw new Error('Missing required parameter: id');
  }

  const path = '/api/account-invoices/{id}'.replace('{id}', parameters.id?.toString());
  return axios({
    method: 'PUT',
    url: path,
    data: encodeBody(form),
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

export type getAccountInvoiceByIdParameters = { id: string };
export type getAccountInvoiceByIdResponse = Promise<AxiosResponse<Types.AccountInvoiceDto>>;

export type getAccountInvoiceById = (
  parameters: getAccountInvoiceByIdParameters,
  cancelToken?: CancelToken,
) => getAccountInvoiceByIdResponse;

export const getAccountInvoiceById: getAccountInvoiceById = async (parameters, cancelToken) => {
  if (parameters.id === undefined) {
    throw new Error('Missing required parameter: id');
  }

  const path = '/api/account-invoices/{id}'.replace('{id}', parameters.id?.toString());
  return axios({
    method: 'GET',
    url: path,
    data: {},
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
    },
  });
};

export type deleteAccountInvoiceParameters = { id: string };
export type deleteAccountInvoiceResponse = Promise<AxiosResponse<any>>;

export type deleteAccountInvoice = (
  parameters: deleteAccountInvoiceParameters,
  cancelToken?: CancelToken,
) => deleteAccountInvoiceResponse;

export const deleteAccountInvoice: deleteAccountInvoice = async (parameters, cancelToken) => {
  if (parameters.id === undefined) {
    throw new Error('Missing required parameter: id');
  }

  const path = '/api/account-invoices/{id}'.replace('{id}', parameters.id?.toString());
  return axios({
    method: 'DELETE',
    url: path,
    data: {},
    cancelToken,
    transformResponse,
  });
};

export type deleteAccountInvoicesParameters = { ids?: string[] };
export type deleteAccountInvoicesResponse = Promise<AxiosResponse<any>>;

export type deleteAccountInvoices = (
  parameters: deleteAccountInvoicesParameters,
  cancelToken?: CancelToken,
) => deleteAccountInvoicesResponse;

export const deleteAccountInvoices: deleteAccountInvoices = async (parameters, cancelToken) => {
  const path = '/api/account-invoices/bulk-delete';
  const query = {
    ids: parameters.ids,
  };

  return axios({
    method: 'DELETE',
    url: `${path}${encodeQueryParams(query, { arrayFormat: 'comma' })}`,
    data: {},
    cancelToken,
    transformResponse,
  });
};

export type markAsPaidAccountInvoiceParameters = { id: string; paid: boolean };
export type markAsPaidAccountInvoiceResponse = Promise<AxiosResponse<Types.AccountInvoiceDto>>;

export type markAsPaidAccountInvoice = (
  parameters: markAsPaidAccountInvoiceParameters,
  cancelToken?: CancelToken,
) => markAsPaidAccountInvoiceResponse;

export const markAsPaidAccountInvoice: markAsPaidAccountInvoice = async (parameters, cancelToken) => {
  if (parameters.id === undefined) {
    throw new Error('Missing required parameter: id');
  }
  if (parameters.paid === undefined) {
    throw new Error('Missing required parameter: paid');
  }

  const path = '/api/account-invoices/{id}/paid'.replace('{id}', parameters.id?.toString());
  const query = {
    paid: parameters.paid,
  };

  return axios({
    method: 'PUT',
    url: `${path}${encodeQueryParams(query, { arrayFormat: 'comma' })}`,
    data: {},
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
    },
  });
};

export type getAccountInvoiceHistoryParameters = {
  id: string;
  page?: number;
  size?: number;
  order?: string;
  sort?: string;
};
export type getAccountInvoiceHistoryResponse = Promise<AxiosResponse<Types.AccountInvoiceHistoryResponse>>;

export type getAccountInvoiceHistory = (
  parameters: getAccountInvoiceHistoryParameters,
  cancelToken?: CancelToken,
) => getAccountInvoiceHistoryResponse;

export const getAccountInvoiceHistory: getAccountInvoiceHistory = async (parameters, cancelToken) => {
  if (parameters.id === undefined) {
    throw new Error('Missing required parameter: id');
  }

  const path = '/api/account-invoices/{id}/history'.replace('{id}', parameters.id?.toString());
  const query = {
    page: parameters.page,
    size: parameters.size,
    order: parameters.order,
    sort: parameters.sort,
  };

  return axios({
    method: 'GET',
    url: `${path}${encodeQueryParams(query, { arrayFormat: 'comma' })}`,
    data: {},
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
    },
  });
};

export type markAsPaidAccountInvoicesParameters = { ids?: string[]; paid: boolean };
export type markAsPaidAccountInvoicesResponse = Promise<AxiosResponse<Types.AccountInvoiceDto[]>>;

export type markAsPaidAccountInvoices = (
  parameters: markAsPaidAccountInvoicesParameters,
  cancelToken?: CancelToken,
) => markAsPaidAccountInvoicesResponse;

export const markAsPaidAccountInvoices: markAsPaidAccountInvoices = async (parameters, cancelToken) => {
  if (parameters.paid === undefined) {
    throw new Error('Missing required parameter: paid');
  }

  const path = '/api/account-invoices/bulk-paid';
  const query = {
    ids: parameters.ids,
    paid: parameters.paid,
  };

  return axios({
    method: 'PUT',
    url: `${path}${encodeQueryParams(query, { arrayFormat: 'comma' })}`,
    data: {},
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
    },
  });
};

export type getAttachmentsByIdsResponse = Promise<AxiosResponse<Types.AttachmentDto[]>>;

export type getAttachmentsByIds = (ids: string[], cancelToken?: CancelToken) => getAttachmentsByIdsResponse;

export const getAttachmentsByIds: getAttachmentsByIds = async (ids, cancelToken) => {
  if (ids === undefined) {
    throw new Error('Missing required body: ids');
  }

  const path = '/api/attachments/by-ids';
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody(ids),
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

export type createAttachmentParameters = { attachmentType: string };
export type createAttachmentResponse = Promise<AxiosResponse<Types.AttachmentDto>>;

export type createAttachment = (
  formData: any,
  parameters: createAttachmentParameters,
  onUploadProgress?: (event: AxiosProgressEvent) => void,
  cancelToken?: CancelToken,
) => createAttachmentResponse;

export const createAttachment: createAttachment = async (formData, parameters, onUploadProgress, cancelToken) => {
  if (parameters.attachmentType === undefined) {
    throw new Error('Missing required parameter: attachmentType');
  }

  const path = '/api/attachments';
  const query = {
    attachmentType: parameters.attachmentType,
  };

  return axios({
    method: 'POST',
    url: `${path}${encodeQueryParams(query, { arrayFormat: 'comma' })}`,
    data: formData,
    onUploadProgress,
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  });
};

export type markDeletedAttachmentParameters = { id: string; isDeleted: boolean };
export type markDeletedAttachmentResponse = Promise<AxiosResponse<Types.AttachmentDto>>;

export type markDeletedAttachment = (
  parameters: markDeletedAttachmentParameters,
  cancelToken?: CancelToken,
) => markDeletedAttachmentResponse;

export const markDeletedAttachment: markDeletedAttachment = async (parameters, cancelToken) => {
  if (parameters.id === undefined) {
    throw new Error('Missing required parameter: id');
  }
  if (parameters.isDeleted === undefined) {
    throw new Error('Missing required parameter: isDeleted');
  }

  const path = '/api/attachments/{id}/mark-deleted'.replace('{id}', parameters.id?.toString());
  const query = {
    isDeleted: parameters.isDeleted,
  };

  return axios({
    method: 'PUT',
    url: `${path}${encodeQueryParams(query, { arrayFormat: 'comma' })}`,
    data: {},
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
    },
  });
};

export type downloadAttachmentParameters = { id: string };
export type downloadAttachmentResponse = Promise<AxiosResponse<Types.FileDto>>;

export type downloadAttachment = (
  parameters: downloadAttachmentParameters,
  cancelToken?: CancelToken,
) => downloadAttachmentResponse;

export const downloadAttachment: downloadAttachment = async (parameters, cancelToken) => {
  if (parameters.id === undefined) {
    throw new Error('Missing required parameter: id');
  }

  const path = '/api/attachments/{id}/download'.replace('{id}', parameters.id?.toString());
  return axios({
    method: 'GET',
    url: path,
    data: {},
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
    },
  });
};

export type fetchClientDashboardDtoParameters = { accountIds: string[] };
export type fetchClientDashboardDtoResponse = Promise<AxiosResponse<Types.ClientDashboardDto>>;

export type fetchClientDashboardDto = (
  parameters: fetchClientDashboardDtoParameters,
  cancelToken?: CancelToken,
) => fetchClientDashboardDtoResponse;

export const fetchClientDashboardDto: fetchClientDashboardDto = async (parameters, cancelToken) => {
  if (parameters.accountIds === undefined) {
    throw new Error('Missing required parameter: accountIds');
  }

  const path = '/api/dashboards/client';
  const query = {
    accountIds: parameters.accountIds,
  };

  return axios({
    method: 'GET',
    url: `${path}${encodeQueryParams(query, { arrayFormat: 'comma' })}`,
    data: {},
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
    },
  });
};

export type fetchDeveloperDashboardDtoResponse = Promise<AxiosResponse<Types.DeveloperDashboardDto>>;

export type fetchDeveloperDashboardDto = (cancelToken?: CancelToken) => fetchDeveloperDashboardDtoResponse;

export const fetchDeveloperDashboardDto: fetchDeveloperDashboardDto = async (cancelToken) => {
  const path = '/api/dashboards/developer';
  return axios({
    method: 'GET',
    url: path,
    data: {},
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
    },
  });
};

export type fetchBookkeeperDashboardDtoParameters = { accountId?: string; userId?: string };
export type fetchBookkeeperDashboardDtoResponse = Promise<AxiosResponse<Types.BookkeeperDashboardDto>>;

export type fetchBookkeeperDashboardDto = (
  parameters: fetchBookkeeperDashboardDtoParameters,
  cancelToken?: CancelToken,
) => fetchBookkeeperDashboardDtoResponse;

export const fetchBookkeeperDashboardDto: fetchBookkeeperDashboardDto = async (parameters, cancelToken) => {
  const path = '/api/dashboards/bookkeeper';
  const query = {
    accountId: parameters.accountId,
    userId: parameters.userId,
  };

  return axios({
    method: 'GET',
    url: `${path}${encodeQueryParams(query, { arrayFormat: 'comma' })}`,
    data: {},
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
    },
  });
};

export type getEmailsParameters = { filter?: string; page?: number; size?: number; order?: string; sort?: string };
export type getEmailsResponse = Promise<AxiosResponse<Types.EmailResponse>>;

export type getEmails = (parameters: getEmailsParameters, cancelToken?: CancelToken) => getEmailsResponse;

export const getEmails: getEmails = async (parameters, cancelToken) => {
  const path = '/api/emails';
  const query = {
    filter: parameters.filter,
    page: parameters.page,
    size: parameters.size,
    order: parameters.order,
    sort: parameters.sort,
  };

  return axios({
    method: 'GET',
    url: `${path}${encodeQueryParams(query, { arrayFormat: 'comma' })}`,
    data: {},
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
    },
  });
};

export type getNotificationsParameters = {
  read?: boolean;
  page?: number;
  size?: number;
  order?: string;
  sort?: string;
};
export type getNotificationsResponse = Promise<AxiosResponse<Types.NotificationResponse>>;

export type getNotifications = (
  parameters: getNotificationsParameters,
  cancelToken?: CancelToken,
) => getNotificationsResponse;

export const getNotifications: getNotifications = async (parameters, cancelToken) => {
  const path = '/api/notifications';
  const query = {
    read: parameters.read,
    page: parameters.page,
    size: parameters.size,
    order: parameters.order,
    sort: parameters.sort,
  };

  return axios({
    method: 'GET',
    url: `${path}${encodeQueryParams(query, { arrayFormat: 'comma' })}`,
    data: {},
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
    },
  });
};

export type createNotificationParameters = { notifyAll?: boolean };
export type createNotificationResponse = Promise<AxiosResponse<Types.NotificationDto>>;

export type createNotification = (
  form: Types.NotificationForm,
  parameters: createNotificationParameters,
  cancelToken?: CancelToken,
) => createNotificationResponse;

export const createNotification: createNotification = async (form, parameters, cancelToken) => {
  if (form === undefined) {
    throw new Error('Missing required body: form');
  }

  const path = '/api/notifications';
  const query = {
    notifyAll: parameters.notifyAll,
  };

  return axios({
    method: 'POST',
    url: `${path}${encodeQueryParams(query, { arrayFormat: 'comma' })}`,
    data: encodeBody(form),
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

export type countNotificationsParameters = { read?: boolean };
export type countNotificationsResponse = Promise<AxiosResponse<number>>;

export type countNotifications = (
  parameters: countNotificationsParameters,
  cancelToken?: CancelToken,
) => countNotificationsResponse;

export const countNotifications: countNotifications = async (parameters, cancelToken) => {
  const path = '/api/notifications/count';
  const query = {
    read: parameters.read,
  };

  return axios({
    method: 'GET',
    url: `${path}${encodeQueryParams(query, { arrayFormat: 'comma' })}`,
    data: {},
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
    },
  });
};

export type updateNotificationParameters = { id: string };
export type updateNotificationResponse = Promise<AxiosResponse<Types.NotificationDto>>;

export type updateNotification = (
  form: Types.NotificationForm,
  parameters: updateNotificationParameters,
  cancelToken?: CancelToken,
) => updateNotificationResponse;

export const updateNotification: updateNotification = async (form, parameters, cancelToken) => {
  if (form === undefined) {
    throw new Error('Missing required body: form');
  }
  if (parameters.id === undefined) {
    throw new Error('Missing required parameter: id');
  }

  const path = '/api/notifications/{id}'.replace('{id}', parameters.id?.toString());
  return axios({
    method: 'PUT',
    url: path,
    data: encodeBody(form),
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

export type markReadParameters = { id: string; read: boolean };
export type markReadResponse = Promise<AxiosResponse<any>>;

export type markRead = (parameters: markReadParameters, cancelToken?: CancelToken) => markReadResponse;

export const markRead: markRead = async (parameters, cancelToken) => {
  if (parameters.id === undefined) {
    throw new Error('Missing required parameter: id');
  }
  if (parameters.read === undefined) {
    throw new Error('Missing required parameter: read');
  }

  const path = '/api/notifications/{id}'.replace('{id}', parameters.id?.toString());
  const query = {
    read: parameters.read,
  };

  return axios({
    method: 'PATCH',
    url: `${path}${encodeQueryParams(query, { arrayFormat: 'comma' })}`,
    data: {},
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
    },
  });
};

export type getTicketsParameters = { open?: boolean; page?: number; size?: number; order?: string; sort?: string };
export type getTicketsResponse = Promise<AxiosResponse<Types.TicketResponse>>;

export type getTickets = (parameters: getTicketsParameters, cancelToken?: CancelToken) => getTicketsResponse;

export const getTickets: getTickets = async (parameters, cancelToken) => {
  const path = '/api/tickets';
  const query = {
    open: parameters.open,
    page: parameters.page,
    size: parameters.size,
    order: parameters.order,
    sort: parameters.sort,
  };

  return axios({
    method: 'GET',
    url: `${path}${encodeQueryParams(query, { arrayFormat: 'comma' })}`,
    data: {},
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
    },
  });
};

export type createTicketResponse = Promise<AxiosResponse<Types.TicketDto>>;

export type createTicket = (form: Types.TicketForm, cancelToken?: CancelToken) => createTicketResponse;

export const createTicket: createTicket = async (form, cancelToken) => {
  if (form === undefined) {
    throw new Error('Missing required body: form');
  }

  const path = '/api/tickets';
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody(form),
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

export type countTicketsParameters = { open?: boolean };
export type countTicketsResponse = Promise<AxiosResponse<number>>;

export type countTickets = (parameters: countTicketsParameters, cancelToken?: CancelToken) => countTicketsResponse;

export const countTickets: countTickets = async (parameters, cancelToken) => {
  const path = '/api/tickets/count';
  const query = {
    open: parameters.open,
  };

  return axios({
    method: 'GET',
    url: `${path}${encodeQueryParams(query, { arrayFormat: 'comma' })}`,
    data: {},
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
    },
  });
};

export type markTicketClosedParameters = { id: string; closed: boolean };
export type markTicketClosedResponse = Promise<AxiosResponse<Types.TicketDto>>;

export type markTicketClosed = (
  parameters: markTicketClosedParameters,
  cancelToken?: CancelToken,
) => markTicketClosedResponse;

export const markTicketClosed: markTicketClosed = async (parameters, cancelToken) => {
  if (parameters.id === undefined) {
    throw new Error('Missing required parameter: id');
  }
  if (parameters.closed === undefined) {
    throw new Error('Missing required parameter: closed');
  }

  const path = '/api/tickets/{id}/close'.replace('{id}', parameters.id?.toString());
  const query = {
    closed: parameters.closed,
  };

  return axios({
    method: 'PATCH',
    url: `${path}${encodeQueryParams(query, { arrayFormat: 'comma' })}`,
    data: {},
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
    },
  });
};

export type getTicketMessagesParameters = { id: string; page?: number; size?: number; order?: string; sort?: string };
export type getTicketMessagesResponse = Promise<AxiosResponse<Types.TicketMessageResponse>>;

export type getTicketMessages = (
  parameters: getTicketMessagesParameters,
  cancelToken?: CancelToken,
) => getTicketMessagesResponse;

export const getTicketMessages: getTicketMessages = async (parameters, cancelToken) => {
  if (parameters.id === undefined) {
    throw new Error('Missing required parameter: id');
  }

  const path = '/api/tickets/{id}/messages'.replace('{id}', parameters.id?.toString());
  const query = {
    page: parameters.page,
    size: parameters.size,
    order: parameters.order,
    sort: parameters.sort,
  };

  return axios({
    method: 'GET',
    url: `${path}${encodeQueryParams(query, { arrayFormat: 'comma' })}`,
    data: {},
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
    },
  });
};

export type createTicketMessageParameters = { id: string };
export type createTicketMessageResponse = Promise<AxiosResponse<Types.TicketMessageDto>>;

export type createTicketMessage = (
  form: Types.TicketMessageForm,
  parameters: createTicketMessageParameters,
  cancelToken?: CancelToken,
) => createTicketMessageResponse;

export const createTicketMessage: createTicketMessage = async (form, parameters, cancelToken) => {
  if (parameters.id === undefined) {
    throw new Error('Missing required parameter: id');
  }
  if (form === undefined) {
    throw new Error('Missing required body: form');
  }

  const path = '/api/tickets/{id}/messages'.replace('{id}', parameters.id?.toString());
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody(form),
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

export type createTicketAttachmentParameters = { attachmentType: string };
export type createTicketAttachmentResponse = Promise<AxiosResponse<Types.AttachmentDto>>;

export type createTicketAttachment = (
  formData: any,
  parameters: createTicketAttachmentParameters,
  onUploadProgress?: (event: AxiosProgressEvent) => void,
  cancelToken?: CancelToken,
) => createTicketAttachmentResponse;

export const createTicketAttachment: createTicketAttachment = async (
  formData,
  parameters,
  onUploadProgress,
  cancelToken,
) => {
  if (parameters.attachmentType === undefined) {
    throw new Error('Missing required parameter: attachmentType');
  }

  const path = '/api/tickets/attachments';
  const query = {
    attachmentType: parameters.attachmentType,
  };

  return axios({
    method: 'POST',
    url: `${path}${encodeQueryParams(query, { arrayFormat: 'comma' })}`,
    data: formData,
    onUploadProgress,
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  });
};

export type getCountryCodesResponse = Promise<AxiosResponse<string[]>>;

export type getCountryCodes = (cancelToken?: CancelToken) => getCountryCodesResponse;

export const getCountryCodes: getCountryCodes = async (cancelToken) => {
  const path = '/api/master-data/country-codes';
  return axios({
    method: 'GET',
    url: path,
    data: {},
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
    },
  });
};

export type getTimeZonesResponse = Promise<AxiosResponse<string[]>>;

export type getTimeZones = (cancelToken?: CancelToken) => getTimeZonesResponse;

export const getTimeZones: getTimeZones = async (cancelToken) => {
  const path = '/api/master-data/time-zones';
  return axios({
    method: 'GET',
    url: path,
    data: {},
    cancelToken,
    transformResponse,
    headers: {
      Accept: 'application/json',
    },
  });
};

export * from './types';
