import axios, { AxiosError, AxiosResponse, CancelTokenSource } from 'axios';
import { makeAutoObservable } from 'mobx';

type PromiseState = 'pending' | 'fulfilled' | 'rejected' | 'cancelled';

type ErrorDataType = {
  error: string;
  exception: string;
  message: string;
  path: string;
  status: number;
  timestamp: number;
  trace: string;
};

function unwrapResponse<T>(resp: AxiosResponse<T>) {
  return resp.data;
}

function unwrapError(err: AxiosError<ErrorDataType>) {
  return err.response?.data;
}

export class PromiseObserver<T> implements Promise<AxiosResponse<T>> {
  readonly [Symbol.toStringTag] = 'PromiseObserver';
  private target: Promise<AxiosResponse<T>>;
  private state: PromiseState = 'pending';
  private promiseValue?: T = undefined;
  private promiseError?: ErrorDataType = undefined;
  private cancelSource?: CancelTokenSource = undefined;

  constructor(target: Promise<AxiosResponse<T>>, oldValue?: T, cancelSource?: CancelTokenSource) {
    this.target = target;
    this.promiseValue = oldValue;
    this.cancelSource = cancelSource;

    makeAutoObservable(this);

    target.then(this.onResolve).catch(this.onReject);
  }

  cancel = () => {
    this.cancelSource?.cancel();
  };

  then<TResult1 = AxiosResponse<T>, TResult2 = never>(
    onfulfilled?: ((value: AxiosResponse<T>) => TResult1 | PromiseLike<TResult1>) | null,
    onrejected?: ((reason: unknown) => TResult2 | PromiseLike<TResult2>) | null,
  ): Promise<TResult1 | TResult2> {
    return this.target.then(onfulfilled, onrejected);
  }

  catch<TResult = never>(
    onrejected?: ((reason: unknown) => TResult | PromiseLike<TResult>) | null,
  ): Promise<AxiosResponse<T> | TResult> {
    return this.target.catch(onrejected);
  }

  finally(onfinally?: (() => void) | null): Promise<AxiosResponse<T>> {
    return this.target.finally(onfinally);
  }

  private onReject = (error?: AxiosError<ErrorDataType>) => {
    if (axios.isCancel(error)) {
      this.state = 'cancelled';
    } else {
      // eslint-disable-next-line
      // @ts-ignore
      this.promiseError = unwrapError(error);
      this.state = 'rejected';
    }
  };

  private onResolve = (value: AxiosResponse<T>) => {
    this.promiseValue = unwrapResponse(value);
    this.state = 'fulfilled';
  };

  get pending() {
    return this.state === 'pending';
  }

  get rejected() {
    return this.state === 'rejected';
  }

  get fulfilled() {
    return this.state === 'fulfilled';
  }

  get cancelled() {
    return this.state === 'cancelled';
  }

  get value() {
    return this.promiseValue;
  }

  get error() {
    return this.promiseError;
  }
}

export function fromPromise<T>(
  origPromise: Promise<AxiosResponse<T>>,
  options?: { oldData?: T; cancelSource?: CancelTokenSource },
) {
  return new PromiseObserver<T>(origPromise, options?.oldData, options?.cancelSource);
}
