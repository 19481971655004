import { App } from 'antd';
import { ArgsProps } from 'antd/es/notification/interface';
import { SortOrder } from 'antd/es/table/interface';
import { reaction } from 'mobx';
import { useEffect } from 'react';
import { PromiseObserver } from './FromPromise';

export type ApiOrder = 'asc' | 'desc';

export function getApiSortOrder(antOrder?: SortOrder): ApiOrder | undefined {
  return antOrder === 'ascend' ? 'asc' : antOrder === 'descend' ? 'desc' : undefined;
}

export function useWatchError(promise?: PromiseObserver<any>) {
  const { notification } = App.useApp();

  useEffect(
    () =>
      reaction(
        () => promise?.rejected,
        (rejected) => rejected && notification.error({ message: promise?.error?.message }),
      ),
    [notification, promise],
  );
}

export function useWatchSuccess<T extends CallableFunction>(
  promise?: PromiseObserver<any>,
  message?: string | ArgsProps,
  callback?: T,
) {
  const { notification } = App.useApp();

  useEffect(
    () =>
      reaction(
        () => promise?.fulfilled,
        (fulfilled) => {
          if (fulfilled) {
            if (message) {
              typeof message === 'string' ? notification.success({ message }) : notification.success(message);
            }
            callback?.();
          }
        },
      ),
    [callback, message, notification, promise],
  );
}

export function dataURLtoBlob(dataurl: string) {
  const bstr = atob(dataurl);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new Blob([u8arr]);
}
