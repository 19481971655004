import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker/generatePicker';
import { Dayjs } from 'dayjs';
import React from 'react';

const { RangePicker } = DatePicker;

export type RangeFieldProps = RangePickerProps<Dayjs>;

export function DateRangeField(props: RangeFieldProps) {
  const { format = 'L', ...restProps } = props;

  return <RangePicker format={format} {...restProps} />;
}
