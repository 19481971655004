import { App as AntApp, ConfigProvider, Modal, Spin, theme } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import en_GB from 'antd/locale/en_GB';
import axios from 'axios';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { configure } from 'mobx';
import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './common.css';
import { Pages } from './pages';

dayjs.locale('en-gb');
dayjs.extend(localizedFormat);

axios.defaults.baseURL = process.env.REACT_APP_API_DOMAIN;

Modal.defaultProps = { ...Modal.defaultProps, maskClosable: false };
Spin.defaultProps = { ...Spin.defaultProps, indicator: <LoadingOutlined /> };

const App = () => {
  useEffect(() => {
    configure({
      enforceActions: 'observed',
      computedRequiresReaction: true,
      reactionRequiresObservable: true,
      observableRequiresReaction: true,
      disableErrorBoundaries: false,
    });
  }, []);

  return (
    <ConfigProvider
      locale={en_GB}
      theme={{
        algorithm: theme.defaultAlgorithm,
        token: {
          colorPrimary: '#eb3333',
          colorLink: '#2646E6',
          colorLinkHover: '#0270DC',
          colorSuccess: '#23BB4A',
          colorWarning: '#EB7200',
          colorError: '#eb3333',
          colorTextDisabled: '#00000073',
        },
        components: {
          Menu: {
            colorItemBgSelected: 'transparent',
            colorItemTextSelected: '#eb3333',
          },
        },
      }}>
      <BrowserRouter>
        <AntApp>
          <Pages />
        </AntApp>
      </BrowserRouter>
    </ConfigProvider>
  );
};

export default App;
