import {
  BankOutlined,
  BarChartOutlined,
  CoffeeOutlined,
  DollarOutlined,
  IdcardOutlined,
  MoneyCollectOutlined,
  UserOutlined,
  MailOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AppRoutes } from '../common/AppRoutes';
import { authController } from '../common/AuthController';

const Wrapper = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${theme.colorBgContainer} !important;
`,
);

const MenuWrapper = styled.div(
  ({ theme }) => `
  padding: ${theme.paddingSM}px 0;
  flex: 1 1 auto;
  overflow: auto;
`,
);

// eslint-disable-next-line complexity
export const AppMenu = observer(function AppMenu() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = authController;

  const isAdmin = !!user?.roles.some((r) => r === 'ADMIN');
  const isClient = !!user?.roles.some((r) => r === 'CLIENT');
  const isBookkeeper = !!user?.roles.some((r) => r === 'BOOKKEEPER');
  const isHr = !!user?.roles.some((r) => r === 'HR');
  const isDeveloper = !!user?.roles.some((r) => r === 'DEVELOPER');

  const links = [];
  links.push({
    key: AppRoutes.DASHBOARD,
    label: 'Dashboard',
    icon: <BarChartOutlined />,
    onClick: () => {
      navigate(AppRoutes.DASHBOARD);
    },
  });
  (isAdmin || isBookkeeper || isClient) &&
    links.push({
      key: AppRoutes.ACCOUNTS,
      label: 'Accounts',
      icon: <BankOutlined />,
      onClick: () => {
        navigate(AppRoutes.ACCOUNTS);
      },
    });
  (isAdmin || isBookkeeper || isClient) &&
    links.push({
      key: AppRoutes.USERS,
      label: 'Users',
      icon: <UserOutlined />,
      onClick: () => {
        navigate(AppRoutes.USERS);
      },
    });
  (isAdmin || isBookkeeper || isClient) &&
    links.push({
      key: AppRoutes.INVOICES,
      label: 'Invoices',
      icon: <MoneyCollectOutlined />,
      onClick: () => {
        navigate(AppRoutes.INVOICES);
      },
    });
  (isAdmin || isBookkeeper || isDeveloper) &&
    links.push({
      key: AppRoutes.PAYMENTS,
      label: 'Payments',
      icon: <DollarOutlined />,
      onClick: () => {
        navigate(AppRoutes.PAYMENTS);
      },
    });
  links.push({
    key: AppRoutes.VACATIONS,
    label: 'Vacations',
    icon: <CoffeeOutlined />,
    onClick: () => {
      navigate(AppRoutes.VACATIONS);
    },
  });
  (isAdmin || isHr) &&
    links.push({
      key: AppRoutes.CANDIDATES,
      label: 'HR',
      icon: <IdcardOutlined />,
      onClick: () => {
        navigate(AppRoutes.CANDIDATES);
      },
    });
  (isAdmin || isBookkeeper || isHr) &&
    links.push({
      key: AppRoutes.EMAIL,
      label: 'Email',
      icon: <MailOutlined />,
      onClick: () => {
        navigate(AppRoutes.EMAIL);
      },
    });

  return (
    <Wrapper>
      <MenuWrapper>
        <Menu
          mode="inline"
          selectedKeys={[location.pathname.split('/')[1] ?? '']}
          style={{ borderRight: 0 }}
          items={links}
        />
      </MenuWrapper>
    </Wrapper>
  );
});
