import { SelectProps, Spin } from 'antd';
import { UserShortDto } from 'js/api/.dist';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo } from 'react';
import { SelectField, useRenderDropdownPart } from '../Fields';
import { PaymentType, Store } from './store';

export type UserPayload = UserShortDto | UserShortDto[] | undefined;

type BaseProps = {
  value?: UserPayload;
  onChange?: (value: UserPayload) => void;
  paymentType?: PaymentType;
  accountId?: string;
  disabledUserIds?: string[];
  autoselect?: boolean;
} & Omit<
  SelectProps,
  | 'value'
  | 'onChange'
  | 'onSearch'
  | 'dropdownRender'
  | 'onDropdownVisibleChange'
  | 'options'
  | 'loading'
  | 'notFoundContent'
  | 'showSearch'
  | 'labelInValue'
  | 'filterOption'
>;

type Props =
  | (BaseProps & { value?: UserShortDto; onChange?: (value?: UserShortDto) => void; mode?: undefined })
  | (BaseProps & { value?: UserShortDto[]; onChange?: (value?: UserShortDto[]) => void; mode: 'multiple' });

function ensureUserFilter(val?: UserShortDto): val is UserShortDto {
  return !!val;
}

export const UserSelect = observer(function UserSelect({
  paymentType,
  accountId,
  disabledUserIds,
  onChange,
  value,
  autoselect,
  ...props
}: Props) {
  const store = useMemo(() => new Store(paymentType, accountId), [paymentType, accountId]);
  const { usersLinkPromise } = store;
  const users = usersLinkPromise?.value;

  useEffect(() => {
    store.checkUsers(true);
    return store.clear;
  }, [store]);

  useEffect(() => {
    const firstVal = users?.items?.[0];
    autoselect && firstVal && !value && onChange?.(firstVal);
  }, [users, autoselect, onChange, value]);

  const checkDisabled = useCallback(
    (id: string) => {
      return !!disabledUserIds?.find((v) => v === id);
    },
    [disabledUserIds],
  );

  const renderDropdown = useRenderDropdownPart(users?.items?.length ?? 0, users?.total ?? 0);

  const onValueChange: NonNullable<SelectProps['onChange']> = useCallback(
    (val) => {
      /** users?.items contain reduced by filter values */
      const mergedUsers = [...(Array.isArray(value) ? value : value ? [value] : []), ...(users?.items ?? [])];
      if (Array.isArray(val)) {
        const typedVal = val as { value: string }[];
        onChange?.(typedVal.map((tv) => mergedUsers.find((v) => v.id === tv.value)).filter(ensureUserFilter));
      } else if (val) {
        const typedVal = val as { value: string };
        onChange?.(mergedUsers.find((v) => v.id === typedVal.value));
      } else {
        onChange?.(undefined);
      }
    },
    [onChange, users?.items, value],
  );

  return (
    <SelectField
      {...props}
      showSearch
      labelInValue
      filterOption={false}
      onSearch={store.setUserFilterString}
      value={
        Array.isArray(value)
          ? value.map((r) => ({ label: `${r.firstName} ${r.lastName}`, value: r.id }))
          : value
          ? { label: `${value.firstName} ${value.lastName}`, value: value.id }
          : undefined
      }
      onChange={onValueChange}
      notFoundContent={usersLinkPromise?.pending ? <Spin size="small" /> : 'No data'}
      loading={usersLinkPromise?.pending}
      options={users?.items?.map((r) => ({
        label: `${r.firstName} ${r.lastName}`,
        value: r.id,
        disabled: checkDisabled(r.id),
      }))}
      onDropdownVisibleChange={store.checkUsers}
      dropdownRender={renderDropdown}
    />
  );
});
