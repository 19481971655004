import { Layout, Spin, theme } from 'antd';
import { observer } from 'mobx-react';
import React, { Suspense, lazy, useEffect } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { AppRoutes } from '../common/AppRoutes';
import { authController } from '../common/AuthController';
import { AppMenu } from '../components/AppMenu';
import { HeaderMenu } from '../components/HeaderMenu';

const LoginPage = lazy(() => import('./LoginPage'));
const DashboardPage = lazy(() => import('./DashboardPage'));
const UsersPage = lazy(() => import('./UsersPage'));
const UserPage = lazy(() => import('./UserPage'));
const AccountsPage = lazy(() => import('./AccountsPage'));
const AccountPage = lazy(() => import('./AccountPage'));
const VacationsPage = lazy(() => import('./VacationsPage'));
const MyPage = lazy(() => import('./MyPage'));
const InvoicesPage = lazy(() => import('./InvoicesPage'));
const InvoicePage = lazy(() => import('./InvoicePage'));
const PaymentsPage = lazy(() => import('./PaymentsPage'));
const PaymentPage = lazy(() => import('./PaymentPage'));
const HRPage = lazy(() => import('./HRPage'));
const InvitePage = lazy(() => import('./InvitePage'));
const EmailPage = lazy(() => import('./EmailPage'));
const NotificationsPage = lazy(() => import('./NotificationsPage'));
const TicketsPage = lazy(() => import('./TicketsPage'));
const TicketPage = lazy(() => import('./TicketPage'));

const { Header, Sider, Content } = Layout;

// eslint-disable-next-line complexity
export const Pages = observer(function Pages() {
  const { token, user } = authController;
  const isAdmin = !!user?.roles.some((r) => r === 'ADMIN');
  const isClient = !!user?.roles.some((r) => r === 'CLIENT');
  const isBookkeeper = !!user?.roles.some((r) => r === 'BOOKKEEPER');
  const isHr = !!user?.roles.some((r) => r === 'HR');
  const isDeveloper = !!user?.roles.some((r) => r === 'DEVELOPER');

  const { token: themeToken } = theme.useToken();
  const { colorBgLayout, paddingMD, paddingSM } = themeToken;

  const links = [];

  links.push({ path: AppRoutes.DASHBOARD, element: <DashboardPage /> });

  if (isAdmin || isBookkeeper || isClient) {
    links.push({ path: AppRoutes.USERS, element: <UsersPage /> });
  }

  if (isAdmin || isBookkeeper) {
    links.push({ path: `${AppRoutes.USERS}/${AppRoutes.USER.NEW}`, element: <UserPage /> });
    links.push({ path: `${AppRoutes.USERS}/${AppRoutes.USER.EXSISTED}`, element: <UserPage /> });
  }

  if (isAdmin || isBookkeeper || isClient) {
    links.push({ path: AppRoutes.ACCOUNTS, element: <AccountsPage /> });
    links.push({ path: `${AppRoutes.ACCOUNTS}/${AppRoutes.ACCOUNT.EXSISTED}`, element: <AccountPage /> });
  }

  if (isAdmin || isBookkeeper) {
    links.push({ path: `${AppRoutes.ACCOUNTS}/${AppRoutes.ACCOUNT.NEW}`, element: <AccountPage /> });
  }

  links.push({ path: AppRoutes.VACATIONS, element: <VacationsPage /> });
  links.push({ path: AppRoutes.MY, element: <MyPage /> });
  links.push({ path: AppRoutes.NOTIFICATIONS, element: <NotificationsPage /> });
  links.push({ path: AppRoutes.TICKETS, element: <TicketsPage /> });
  links.push({ path: `${AppRoutes.TICKETS}/${AppRoutes.TICKET.EXSISTED}`, element: <TicketPage /> });

  if (isAdmin || isBookkeeper || isClient) {
    links.push({ path: AppRoutes.INVOICES, element: <InvoicesPage /> });
    links.push({ path: `${AppRoutes.INVOICES}/${AppRoutes.INVOICE.EXSISTED}`, element: <InvoicePage /> });
  }

  if (isAdmin || isBookkeeper) {
    links.push({ path: `${AppRoutes.INVOICES}/${AppRoutes.INVOICE.NEW}`, element: <InvoicePage /> });
  }

  if (isAdmin || isBookkeeper || isDeveloper) {
    links.push({ path: AppRoutes.PAYMENTS, element: <PaymentsPage /> });
    links.push({ path: `${AppRoutes.PAYMENTS}/${AppRoutes.PAYMENT.EXSISTED}`, element: <PaymentPage /> });
  }

  if (isAdmin || isBookkeeper) {
    links.push({ path: `${AppRoutes.PAYMENTS}/${AppRoutes.PAYMENT.NEW}`, element: <PaymentPage /> });
  }

  if (isAdmin || isHr) {
    links.push({ path: AppRoutes.CANDIDATES, element: <HRPage /> });
  }

  if (isAdmin || isBookkeeper || isHr) {
    links.push({ path: AppRoutes.EMAIL, element: <EmailPage /> });
  }

  links.push({ path: '*', element: <Navigate to={AppRoutes.DASHBOARD} /> });

  const applicationRoutes = useRoutes(links);
  const authRoutes = useRoutes([
    {
      path: '/activate',
      element: <InvitePage />,
    },
    {
      path: '/login',
      element: <LoginPage />,
    },
    {
      path: '/*',
      element: <Navigate to="/login" />,
    },
  ]);

  useEffect(() => {
    authController.init();
  }, []);

  return (
    <ThemeProvider theme={themeToken}>
      <Layout>
        {user && (
          <Header style={{ background: '#fff', paddingLeft: '24px' }}>
            <HeaderMenu />
          </Header>
        )}
        <Layout>
          {user && (
            <Sider width={220}>
              <AppMenu />
            </Sider>
          )}
          <Layout>
            <Suspense fallback={<Spin style={{ margin: 'auto' }} />}>
              {token ? (
                user ? (
                  <Content style={{ backgroundColor: colorBgLayout, padding: `${paddingMD}px ${paddingSM}px` }}>
                    {applicationRoutes}
                  </Content>
                ) : (
                  <Spin style={{ margin: 'auto' }} />
                )
              ) : (
                authRoutes
              )}
            </Suspense>
          </Layout>
        </Layout>
      </Layout>
    </ThemeProvider>
  );
});
