import { NotificationResponse, getNotifications, getNotificationsParameters, NotificationDto, countNotifications } from '@simbaze/api';
import { TablePaginationConfig } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import axios from 'axios';
import { makeAutoObservable, reaction } from 'mobx';
import { getApiSortOrder } from '../../common/apiHelper';
import { authController } from '../../common/AuthController';
import { fromPromise, PromiseObserver } from '../../common/FromPromise';

const defaultPagination: TablePaginationConfig = {
  current: 1,
  pageSize: 10,
  showSizeChanger: true,
  showTotal(total, [from, to]) {
    return `${from} - ${to} of ${total}`;
  },
};

const defaultSorter: SorterResult<NotificationDto> = { field: 'createdTime', order: 'descend' };

class TableStore {
  private currentPagination?: TablePaginationConfig = undefined;
  private currentSorter?: SorterResult<NotificationDto> = undefined;

  filterString?: string = undefined;
  dataPromise?: PromiseObserver<NotificationResponse> = undefined;
  countNotificationsPromise?: PromiseObserver<number> = undefined;

  constructor() {
    makeAutoObservable(this);

      reaction(
      () => this.sorter,
      () => this.fetch(),
    );

    reaction(
      () => this.pagination.current,
      () => this.fetch(),
    );

    reaction(
      () => this.pagination.pageSize,
      () => this.resetFetch(),
    );

    reaction(
      () => authController.user,
      (user) => !user && this.clear(),
    );
  }

  private get total() {
    return this.dataPromise?.value?.total ?? 0;
  }

  get pagination(): TablePaginationConfig {
    return { ...defaultPagination, ...this.currentPagination, total: this.total };
  }
    
  get sorter(): SorterResult<NotificationDto> {
    return { ...defaultSorter, ...this.currentSorter };
  }

  private get requestParams(): getNotificationsParameters {
    return {
        page: (this.pagination.current ?? 1) - 1,
        size: this.pagination.pageSize,
        sort: String(this.sorter.field),
        order: getApiSortOrder(this.sorter.order),
    };
  }

  get data(): NotificationDto[] {
    return this.dataPromise?.value?.items ?? [];
  }

  get loading() {
    return !!this.dataPromise?.pending;
  }

  private setCurrentPagination = (pagination: TablePaginationConfig) => {
    this.currentPagination = pagination;
  };
    
  private setCurrentSorter = (sorter: SorterResult<NotificationDto>) => {
    this.currentSorter = sorter;
  };

  setFilterString = (filterString?: string) => {
    this.filterString = filterString;
  };

  fetch = () => {
    this.dataPromise?.cancel();
    const cs = axios.CancelToken.source();
    this.dataPromise = fromPromise(getNotifications(this.requestParams, cs.token), {
      cancelSource: cs,
      oldData: this.dataPromise?.value,
    });
  };

  fetchCountNotifications = () => {
    this.countNotificationsPromise?.cancel();
    const cs = axios.CancelToken.source();
    this.countNotificationsPromise = fromPromise(countNotifications({read: false}, cs.token), {
      cancelSource: cs,
      oldData: this.countNotificationsPromise?.value,
    });
  };

  private resetFetch = () => {
    this.pagination.current === 1 ? this.fetch() : this.setCurrentPagination({ ...this.currentPagination, current: 1 });
  };
    
  setViewParams = (
    pagination: TablePaginationConfig,
    _: unknown,
    sorter: SorterResult<NotificationDto> | SorterResult<NotificationDto>[],
  ) => {
    this.setCurrentPagination(pagination);
    !Array.isArray(sorter) && this.setCurrentSorter(sorter);
  };

  clear = () => {
    this.dataPromise?.cancel();
    this.dataPromise = undefined;
    this.countNotificationsPromise?.cancel();
    this.countNotificationsPromise = undefined;
    this.currentPagination = undefined;
    this.currentSorter = undefined;
    this.filterString = undefined;
  };
}

export const store = new TableStore();
