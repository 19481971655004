import { DatePicker, DatePickerProps } from 'antd';
import React, { useCallback } from 'react';
import dayjs, { Dayjs } from 'dayjs';

export type DateFieldProps = Omit<DatePickerProps, 'value' | 'onChange' | 'picker'> & {
  value?: string;
  onChange?: (value?: string, stringValue?: string) => void;
};

export function StringDateField(props: DateFieldProps) {
  const { value, onChange, format = 'L', ...restProps } = props;

  const onValueChange = useCallback(
    (val: Dayjs | null, strVal: string) => {
      onChange?.(val?.format('YYYY-MM-DD'), strVal);
    },
    [onChange],
  );

  return (
    <DatePicker
      picker="date"
      value={value ? dayjs(value) : undefined}
      format={format}
      onChange={onValueChange}
      {...restProps}
    />
  );
}
