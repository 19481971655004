import { DatePicker, DatePickerProps } from 'antd';
import React, { useCallback } from 'react';
import dayjs, { Dayjs } from 'dayjs';

export type DateFieldProps = Omit<DatePickerProps, 'value' | 'onChange' | 'picker'> & {
  value?: number;
  onChange?: (value?: number, stringValue?: string) => void;
  showTime?: boolean;
  unix?: boolean;
};

export function DateField(props: DateFieldProps) {
  const { value, onChange, format, showTime, unix, ...restProps } = props;

  const onValueChange = useCallback(
    (val: Dayjs | null, strVal: string) => {
      const time = val?.toDate().getTime();
      onChange?.(typeof time === 'number' && unix ? time / 1000 : time, strVal);
    },
    [onChange, unix],
  );

  return (
    <DatePicker
      picker="date"
      value={value ? dayjs(unix ? value * 1000 : value) : undefined}
      format={format ?? showTime ? 'L HH:mm:ss' : 'L'}
      onChange={onValueChange}
      showTime={showTime}
      {...restProps}
    />
  );
}
