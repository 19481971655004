import { generateToken, getCurrentUser, updateUserProfile, UserDto, UserProfileForm } from '@simbaze/api';
import axios, { AxiosRequestConfig } from 'axios';
import { makeAutoObservable, reaction } from 'mobx';
import { fromPromise, PromiseObserver } from './FromPromise';
import { StoregeController } from './StorageController';

interface AxiosRequestHeaderConfig extends AxiosRequestConfig {
  cache?: boolean;
}

let currentInterceptor: number | undefined = undefined;

function updateInterceptor(tkn?: string, userId?: string) {
  if (currentInterceptor !== undefined) {
    axios.interceptors.request.eject(currentInterceptor);
  }
  currentInterceptor = axios.interceptors.request.use((request: AxiosRequestHeaderConfig) => {
    request.headers = {
      ...request.headers,
      'Cache-Control': 'no-cache',
      'If-Modified-Since': '0',
      ...(tkn
        ? {
          Authorization: `Bearer ${tkn}`,
          }
        : {}),
       ...(userId ? {
            'x-selected-user': btoa(JSON.stringify({ userId: userId})),
          } : {})
        ,
       
    };
    return request;
  });
}


class AuthController {
  token?: string = StoregeController.getToken();
  userId?: string = StoregeController.getUserId();
  tokenPromise?: PromiseObserver<string> = undefined;
  userPromise?: PromiseObserver<UserDto> = undefined;
  updateUserPromise?: PromiseObserver<UserDto> = undefined;
  

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => ({token: this.token, userId: this.userId}),
      ({token, userId}) => {
        updateInterceptor(token, userId);
        StoregeController.persistToken(token);
        StoregeController.persistUserId(userId);
        token && this.fetchUser();
      },
    );

    reaction(
      () => this.tokenPromise?.fulfilled,
      (fulfilled) => {
        fulfilled && (this.token = this.tokenPromise?.value);
      },
    );

    reaction(
      () => this.userPromise?.rejected,
      (rejected) => rejected && this.logout(),
    );
  }

  get user() {
    return this.updateUserPromise?.value ?? this.userPromise?.value;
  }

  fetchUser() {
    this.userPromise?.cancel();
    const cs = axios.CancelToken.source();
    this.userPromise = this.token
      ? fromPromise(getCurrentUser({},cs.token), { cancelSource: cs, oldData: this.user })
      : undefined;
    this.updateUserPromise = undefined;
  }

    fetchSelectedUser() {
    this.userPromise?.cancel();
    const cs = axios.CancelToken.source();
    this.userPromise = this.token
      ? fromPromise(getCurrentUser({selectedUserId: this.userId},cs.token), { cancelSource: cs, oldData: this.user })
      : undefined;
    this.updateUserPromise = undefined;
  }

  init = () => {
    if (this.token) {
      updateInterceptor(this.token, this.userId);
      this.fetchUser();
    }
  };

  login = (email: string, password: string) => {
    this.tokenPromise?.cancel();
    const cs = axios.CancelToken.source();
    this.tokenPromise = fromPromise(
      generateToken(
        {
          email,
          password,
        },
        cs.token,
      ),
      { cancelSource: cs },
    );
  };


  logout = () => {
    this.token = undefined;
    this.tokenPromise = undefined;
    this.userPromise = undefined;
    this.updateUserPromise = undefined;
    this.userId = undefined;
  };

  updateUser = (data: UserProfileForm) => {
    this.updateUserPromise?.cancel();
    const cs = axios.CancelToken.source();
    this.updateUserPromise =
      this.user && fromPromise(updateUserProfile(data, { id: this.user.id }, cs.token), { cancelSource: cs });
  };




}

export const authController = new AuthController();
