import moment, { Moment } from 'moment';
import qs, { IStringifyOptions } from 'qs';

export enum XDateType {
  X = 'X',
  ISO = 'ISO',
}

export const DATE_UNIX = 'X';
export const DATE_UNIX_MS = 'x';
export const DATE_ISO = 'YYYY-MM-DD';
export const DATETIME_ISO = 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]';
export const TIME_ISO = 'HH:mm';

export type TimeType = {
  __identity__: 'WARNING! This is a fake type (TimeType)',
};

export type DateType = {
  __identity__: 'WARNING! This is a fake type (DateType)',
};

export type DateTimeType = {
  __identity__: 'WARNING! This is a fake type (DateTimeType)',
};

export type DateTimeUnixType = {
  __identity__: 'WARNING! This is a fake type (DateTimeUnixType)',
};

type ToTimeTypeReturnValue<T extends Moment | undefined | null> = T extends Moment
  ? TimeType
  : T extends null
    ? null
    : undefined;

export const toTimeType = <T extends Moment | undefined | null>(
  m: T,
): ToTimeTypeReturnValue<T> => m?.format(TIME_ISO) as ToTimeTypeReturnValue<T>;

type FromTimeTypeReturnValue<T extends TimeType | undefined | null> = T extends TimeType
  ? Moment
  : T extends null
    ? null
    : undefined;

export const fromTimeType = <T extends TimeType | undefined>(
  v: T,
): FromTimeTypeReturnValue<T> => moment.utc(v as unknown as string, TIME_ISO) as FromTimeTypeReturnValue<T>;

type ToDateTypeReturnValue<T extends Moment | undefined | null> = T extends Moment
  ? DateType
  : T extends null
    ? null
    : undefined;

export const toDateType = <T extends Moment | undefined | null>(
  m: T,
): ToDateTypeReturnValue<T> => m?.format(DATE_ISO) as ToDateTypeReturnValue<T>;

type FromDateTypeReturnValue<T extends DateType | undefined | null> = T extends DateType
  ? Moment
  : T extends null
    ? null
    : undefined;

export const fromDateType = <T extends DateType | undefined | null>(
  v: T,
): FromDateTypeReturnValue<T> => moment.utc(v as unknown as string, DATE_ISO) as FromDateTypeReturnValue<T>;

type FromDateTimeTypeReturnValue<T extends DateTimeType | DateTimeUnixType | undefined | null> = T extends DateTimeType
  ? Moment
  : T extends DateTimeUnixType
    ? Moment
    : T extends null
      ? null
      : undefined;

export const fromDateTimeType = <T extends DateTimeType | DateTimeUnixType | undefined | null>(
  v: T,
): FromDateTimeTypeReturnValue<T> => {
  if (v === null) return null as FromDateTimeTypeReturnValue<T>;
  if (v === undefined) return undefined as FromDateTimeTypeReturnValue<T>;

  return moment.utc(v as unknown as string | number, typeof v === 'number'
    ? DATE_UNIX
    : DATETIME_ISO) as FromDateTimeTypeReturnValue<T>;
};

type ToDateTimeTypeReturnValue<T extends Moment | undefined | null> = T extends Moment
  ? DateTimeType
  : T extends null
    ? null
    : undefined;

export const toDateTimeType = <T extends Moment | undefined | null>(
  m: T,
): ToDateTimeTypeReturnValue<T> => m?.format(DATETIME_ISO) as ToDateTimeTypeReturnValue<T>;

type ToDateTimeUnixTypeReturnValue<T extends Moment | undefined | null> = T extends Moment
  ? DateTimeUnixType
  : T extends null
    ? null
    : undefined;

export const toDateTimeUnixType = <T extends Moment | undefined | null>(
  m: T,
): ToDateTimeUnixTypeReturnValue<T> => {
  if (m === null) return null as ToDateTimeUnixTypeReturnValue<T>;
  if (m === undefined) return undefined as ToDateTimeUnixTypeReturnValue<T>;

  return +m.format(DATE_UNIX) as unknown as ToDateTimeUnixTypeReturnValue<T>;
};

export const toDateTimeUnixTypeMs = <T extends Moment | undefined | null>(
  m: T,
): ToDateTimeUnixTypeReturnValue<T> => {
  if (m === null) return null as ToDateTimeUnixTypeReturnValue<T>;
  if (m === undefined) return undefined as ToDateTimeUnixTypeReturnValue<T>;

  return +m.format(DATE_UNIX_MS) as unknown as ToDateTimeUnixTypeReturnValue<T>;
};

const stringifyConfig: IStringifyOptions = {
  arrayFormat: 'brackets',
};

// As we have only NULL on backend side & typescript has tons of features for `undefined` type, we cast
// backend `null` into frontend `undefined`
const reviver = <T,>(k: string, v: T): T | undefined => {
  if (v === null) {
    return undefined;
  }
  return v;
};

// the following function has been taken from axios codebase, modified to pass the reviver
// https://github.com/axios/axios/blob/16b5718954d88fbefe17f0b91101d742b63209c7/lib/defaults.js#L57-L65
const transformResponse = [
  (data: string): Record<string, unknown> | string => {
    if (typeof data === 'string') {
      try {
        // eslint-disable-next-line no-param-reassign
        data = JSON.parse(data, reviver);
      } catch (e) { /* Ignore */ }
    }
    return data;
  },
];

const encodeQueryParams = (
  queryParameters: Record<string, unknown>,
  params: IStringifyOptions = stringifyConfig,
): string => {
  const queryParams = queryParameters && Object.keys(queryParameters).length
    ? qs.stringify(queryParameters, params)
    : null;

  return queryParams ? `?${queryParams}` : '';
};

const encodeBody = (body?: Record<string, unknown> | unknown[] | string): string | undefined => {
  if (body && !(body instanceof Array) && !Object.keys(body).length) {
    return undefined;
  }
  if (body && typeof body === 'string') {
    return body;
  }
  return JSON.stringify(body);
};

export {
  transformResponse,
  encodeQueryParams,
  encodeBody,
};

/**
 * Converts to frontends internal date format that is understood by apis' toDateTime.
 *
 * @param date - Common JS Date object.
 * @returns - Internal api date type.
 */
const fromJSDate = (date: Date) => moment(date);

/**
 * Converts to frontends internal dateTime format that is understood by apis' toDateTime.
 *
 * @param date - Common JS Date object.
 * @returns - Internal api datetime type.
 */
const fromJSDateTime = (date: Date) => moment(date);

export {
  fromJSDate,
  fromJSDateTime,
};
